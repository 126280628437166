import Head from "next/head";
import styles from "./layout.module.css";

export default function Layout({ children }) {
  return (
    <>
      <Head>
        <title>Hayya Media Portal</title>
        <meta name="description" content="Hayya Media Portal" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="\assets\images\hayya-fav.png"
        ></link>
         {/* href="/favicon-32x32.webp" */}
      </Head>

      <main>{children}</main>
    </>
  );
}

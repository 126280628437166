import React from "react";

const defaultProps = {
  size: 150,
  overlay: true,
};

const Loader = (props) => {
  const { size, overlay } = props;

  React.useEffect(() => {
    import("lottie-interactive/dist/lottie-interactive.js");
  });

  return (
      <div
          className={`${
              overlay ? "col-md-8 is-loading lottie-spin-loader" : ""
          } d-flex justify-content-center align-items-center`}
      >
        <div>
            <div
                id="cube-loading"
                className="cube-loader"
                style={{ display: "block" }}
            >
                <div className="all-cube">
                    <div className="cube">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.9244 6.7261L5.96218 0.763916L0 6.7261L5.96218 12.6883L11.9244 6.7261Z"
                                fill="#EB5628"
                            ></path>
                        </svg>
                    </div>
                    <div className="cube">
                        <svg
                            width="28"
                            height="28"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19.1252 9.7262L10.0248 0.625732L0.924316 9.7262L10.0248 18.8267L19.1252 9.7262Z"
                                fill="#00CFB7"
                            ></path>
                        </svg>
                    </div>
                    <div className="cube">
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M31.5776 15.7261L15.8515 0L0.125488 15.7261L15.8515 31.4521L31.5776 15.7261Z"
                                fill="#550065"
                            ></path>
                        </svg>
                    </div>
                    <div className="cube">
                        <svg
                            width="28"
                            height="28"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.577148 9.7262L9.67761 0.625732L18.7781 9.7262L9.67761 18.8267L0.577148 9.7262Z"
                                fill="#FF004C"
                            ></path>
                        </svg>
                    </div>
                    <div className="cube">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.77832 6.7261L6.74051 0.763916L12.7027 6.7261L6.74051 12.6883L0.77832 6.7261Z"
                                fill="#FFBF00"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="loader-heading">
                    <p>Loading... Please Wait...</p>
                </div>
            </div>
        </div>
      </div>
  );
};

Loader.defaultProps = defaultProps;

export default Loader;

import styles from "./footer.module.scss";
import Link from "next/link";
import Partners from "../Partners";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import SocialMedia from "../SocialMedia";
export default function Footer() {
  const { t } = useTranslation("common");
  const [language, setLanguage] = useState("english");
  const switchLanguage = (e) => {
    setLanguage(e.target.id);
  };
  return (
    <>
      <Partners />

      <section
        className={`${styles.footer} footer position-relative bg-burgundy-x bg-light-black pt-5`}
      >
        <div className="container pt-md-5">
          <div className="row text-start-x text-light-grey">
            <div className="col-md-4 mb-md-0 mb-4">
              <ul>
                <li>
                  <p className="mb-sm-3 mb-0">{t("discoverMore")}</p>
                </li>
                {/* <li>
                  <a href="https://qatar2022.qa" target="_blank">
                    Qatar2022.qa
                  </a>
                </li>
                <li>
                  <a href="https://fifa.com" target="_blank">
                    FIFA.com
                  </a>
                </li> */}
                <li>
                  <a href="/assets/pdf/Qatar_Map.pdf" target="_blank">
                    {t("map-of-qatar")}
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-sm-4 my-sm-0 my-4 pb-sm-0 pb-4">
              <div className="mb-4">
               
              </div> */}
              {/* <SocialMedia
                Color="#FEFEFD"
                textColor="text-light"
                display="flex-column"
                colMediaNumber=""
                colNumber="col-12 justify-content-between order-3"
                font="ff-graphik"
              /> */}
            {/* </div> */}
            <div className="col-md-4 mb-md-0 mb-4">
              <ul>
                <li className="pb-1">
                  <p className="mb-sm-3 mb-0">{t("moreinfo")}</p>
                </li>
                <li className="pb-1">
                  <Link href={"/cookie-policy"}>
                    <a>{t("cookiePolicy")}</a>
                  </Link>
                </li>
                <li className="pb-1">
                  <Link href={"/privacy-policy"}>
                    <a>{t("privacyPolicy")}</a>
                  </Link>
                </li>
                <li className="pb-1">
                  <Link href={"/terms"}>
                    <a>{t("termsofUse")}</a>
                  </Link>
                </li>
                <li className="pb-1">
                  <a href={t("manualpdf")} target="_blank">
                    {t("manualuser")}
                  </a>
                </li>
                <li className="pb-1">
                  <Link href={"/faqs"} target="_blank">
                    FAQ
                  </Link>
                </li>
                <li className="pb-1">
                {/* <Link> */}
                  <a className=""target="_blank" href="/contact-us">
                    {t("contactus")}
                  </a>
                {/* </Link> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex flex-between footer-bottom">
            <div>
              {/* <img src="/assets/svg/logo.svg" width="100px"/> */}
             <a href="/"> <img src="/assets/images/Logo.png" width="100px"/></a>

              </div>
          <p
            className={`${styles.cpy} text-center text-light-grey pt-md-5-x py-3 mt-auto`}
          >
            {t("cpy")}
          </p>
      
        </div>
</div>
        {/* <div className={styles.welcome}>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </section>
    </>
  );
}

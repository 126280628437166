import { Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import SignInButton from "./SignInButton";
import SignOutButton from "./SignOutButton";
import styles from "./nav.module.scss";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useUserInfo } from "../../services/apiHooks";

export const noArabicRoutes = [
  // "/faqs",
  "/bookings/media-events",
  "/news-services/official-media-releases",
];

export default function languageToggler() {
  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);

  const router = useRouter();

  const isNotArabicRoutes = noArabicRoutes.includes(router.pathname);

  const { t } = useTranslation("common");
  //

  const { status_level, status, hayya_status, accreditation_id } =
    user?.personal_info ?? {};

  const isTester = user?.role === "TESTER";

  const isScApproved =
    status_level === "SC" && status === "approved" && accreditation_id === 1;

  const isHayaApproved =
    status_level === "HAYYA" &&
    status === "approved" &&
    hayya_status === "Approved" &&
    accreditation_id !== 1;

  return (
    <div className="d-flex align-items-center flex-xl-row flex-column">
      {/* old status */}
      {/* <div
        className={` d-flex  px-2 py-3 py-md-0 w-100 ${
          user?.personal_info?.status === "approved" ||
          user?.personal_info?.status === "approved_lusail"
            ? styles.username
            : ""
        } `}
      > */}
      <div
        className={` d-flex  px-2 py-3 py-md-0 w-100 ${
          isTester || isScApproved || isHayaApproved ? styles.username : ""
        } `}
      >
        <AuthenticatedTemplate>
          {user && (
            // old status
            // <Fragment>
            //   {user?.personal_info?.status === "approved" ||
            //   user?.personal_info?.status === "approved_lusail" ? (
            //     <SignOutButton />
            //   ) : (
            //     <SignInButton />
            //   )}
            // </Fragment>
            <Fragment>
              <SignOutButton />
              {/*{isTester || isScApproved || isHayaApproved ? (*/}
              {/*  <SignOutButton />*/}
              {/*) : (*/}
              {/*  <SignInButton />*/}
              {/*)}*/}
            </Fragment>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {/* <img src="/assets/svg/sign-in.svg" alt="sign-in-logo" width="28" /> */}
          <SignInButton />
        </UnauthenticatedTemplate>
      </div>
      {/* <div className={`px-3 d-none d-xl-block`}>
        <img
          src="/assets/svg/vertical-orange-points.svg"
          alt="vertical-points-logo"
          width="6"
          height="30"
        />
      </div>
      <div className="w-100 py-xl-0 p-3 px-5 px-md-0 d-flex btm">
        <Link
          href=""
          locale={router.locale === "en" && !isNotArabicRoutes ? "ar" : "en"}
        >
          <a className={`${styles.langName} mb-0 `}>{t("language")}</a>
        </Link>
      </div> */}
    </div>
  );
}

import useSWR from "swr";
import axios, { servicesInstance } from "./axios";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const serviceFetcher = (url) =>
  servicesInstance.get(url).then((res) => res.data);

const revalidationOptions = {
  revalidateOnFocus: false,
};

export const useRegionInfo = (language) => {
  const { data, error } = useSWR(
    `location-info?lang=${language}`,
    fetcher,
    revalidationOptions
  );

  return {
    region: data?.data?.region,
    weather: data?.data?.weather,
    isRegionInfoLoading: !error && !data,
    isRegionInfoError: error,
  };
};

export const useUserInfo = (authenticated) => {
  const { data, error } = useSWR(
    authenticated ? `me` : null,
    fetcher,
    revalidationOptions
  );

  return {
    user: data?.data,
    isUserInfoLoading: !error && !data,
    isUserInfoError: error,
  };
};

export const useApplicationInfo = (authenticated, id) => {

  const url = authenticated ? (id ? `applications/${id}` : 'applications') : null;

  const { data, error } = useSWR(
    url,
    fetcher,
    revalidationOptions
  );

  return {
    user: data?.data?.user,
    applications: data?.data?.applications,
    application: data?.data?.application,
    isUserInfoLoading: !error && !data,
    isUserInfoError: error,
  };
};

export const useNewsInfo = (language) => {
  const { data, error } = useSWR(
    `news?lang=${language}`,
    fetcher,
    revalidationOptions
  );

  return {
    news: data?.data,
    isNewsInfoLoading: !error && !data,
    isNewsInfoError: error,
  };
};

export const useHrUsersInfo = (authenticated) => {
  const { data, error } = useSWR(authenticated ? `hr/users` : null, fetcher);

  return {
    hrUsers: data?.data,
    isHrUsersInfoLoading: !error && !data,
    isHrUsersInfoError: error,
  };
};

export const useAlbumsInfo = (authenticated, page, selectedFilters) => {
  const filterIds = selectedFilters.join(",");

  const { data, error } = useSWR(
    authenticated
      ? `albums?page=${page}&per_page=8&filter[category_id]=${filterIds}`
      : null,
    fetcher
  );

  return {
    albums: data?.data,
    isAlbumsInfoLoading: !error && !data,
    isAlbumsInfoError: error,
  };
};

export const useCategoriesInfo = (authenticated) => {
  const { data, error } = useSWR(authenticated ? `categories` : null, fetcher);

  return {
    categories: data?.data,
    iscategoriesInfoLoading: !error && !data,
    iscategoriesError: error,
  };
};

export const useAlbumsMediaInfo = (authenticated, page, albumId) => {
  const { data, error } = useSWR(
    albumId && authenticated
      ? `albums/${albumId}?page=${page}&per_page=16`
      : null,
    fetcher
  );

  return {
    albumsMedia: data?.data,
    isAlbumsMediaInfoLoading: !error && !data,
    isAlbumsMediaInfoError: error,
  };
};

export const useViewMediaInfo = (authenticated, mediaId) => {
  const { data, error } = useSWR(
    mediaId && authenticated ? `albums/media/${mediaId}` : null,
    fetcher
  );

  return {
    media: data?.data,
    isMediaInfoLoading: !error && !data,
    isMediaInfoError: error,
  };
};

export const useSearchMediaInfo = (authenticated, page, query) => {
  const { data, error } = useSWR(
    query && authenticated
      ? `media/search?search=${query}&page=${page}&per_page=16`
      : null,
    fetcher,
    revalidationOptions
  );

  return {
    searchResults: data?.data,
    isSearchResultsInfoLoading: !error && !data,
    isSearchResultsInfoError: error,
  };
};

export const useVimeoDownloadsInfo = (authenticated, vimeoId) => {
  const { data, error } = useSWR(
    vimeoId && authenticated ? `albums/media/vimeo/${vimeoId}` : null,
    fetcher,
    revalidationOptions
  );

  return {
    vimeoDownloads: data?.data,
    isVimeoDownloadsInfoLoading: !error && !data,
    isVimeoDownloadsInfoError: error,
  };
};

export const useEventsInfo = (
  authenticated,
  perPage,
  page,
  selectedFilters
) => {
  const filterIds = selectedFilters.join(",");
  const { data, error } = useSWR(
    authenticated
      ? `events?page=${page}&per_page=${perPage}&filter[types]=${filterIds}`
      : null,
    fetcher
  );

  return {
    eventsInfo: data?.data,
    isEventsInfoLoading: !error && !data,
    isEventsInfoError: error,
  };
};

export const userEventTypesInfo = (authenticated) => {
  const { data, error } = useSWR(authenticated ? `types` : null, fetcher);

  return {
    types: data?.data,
    isTypesInfoLoading: !error && !data,
    isTypesInfoError: error,
  };
};

import React from "react";
import Header from "./Header";
import Footer from "../../components/Footers";
import Image from "next/image";
import Logo from "./Logo";
import { useLoader } from "../../contexts/LoaderContext";
const defaultProps = {
  signInCol: "col-md-12",
};

const RegisterContainer = (props) => {
  const { children, signInCol, isRegister, isHR, isLoggingIn } = props;
  const { isLoading } = useLoader();
  // console.log(isHR)

  return (
    <div className="registration-wrapper d-flex-x-justify-content-center px-0 position-relative w-100">
      {/* <div className="w-auto position-absolute bg-svg">
        <img src="/assets/svg/left-bg.svg" />
      </div> */}
      <div className="d-flex flex-column-x justify-content-center ">
        <div className="row mx-0 w-100" >
          <div style={{minHeight:"100dvh"}}
            className={`signIn mx-auto p-0 position-relative mt-5 pt-2  ${signInCol} ${
              isLoading ? "is-loading-x" : ""
            }`}
          >
            <div className="container mt-5">
              {
                  !isLoggingIn &&
                   <a href={isHR ? "/hr-dashboard": "/dashboard"} className="d-flex align-items-center">
                    {/* <img src="/assets/images/Forms/Arrow_Left.png" alt="back-arrow" className="back-arrow" /> */}
                    <span  className="back-arrow">←</span>
                    <span className="p-3 text-white">Back To Home</span>
                  </a>
              }
            </div>
            {/* "row w-100 remove_gutter" */}
            {/* <Header isRegister={isRegister} isHR={isHR} /> */}
            <div className={`row w-100 remove_gutter ${isHR && "hr-dashboard"}`} style={{minHeight:"100%"}}>
              {/* <Logo /> */}
              {children}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-auto position-absolute bg-svg-bottom d-none d-md-block">
        <img src="/assets/svg/right-bg-svg.svg" />
      </div> */}
      <Footer />

    </div>
  );
};

RegisterContainer.defaultProps = defaultProps;

export default RegisterContainer;

import { Fragment } from "react";
import AuthHoc from "./Hoc/AuthHoc";
import { useRouter } from "next/router";

export const AuthRoutes = [
  "/dashboard",
  "/news-services/general-insights",
  "/news-services/fwc-2022-fixtures-results",
  "/news-services/official-venues",
  "/news-services/non-official-venues",
  "/news-services/ambassadors",
  "/media-services/entry-to-qatar-and-covid-19-policy",
  "/media-services/host-country-media-accreditation",
  "/media-services/host-country-media-accomodation",
  "/media-services/transportation",
  "/media-services/host-country-media-centre",
  "/media-services/host-country-broadcast-facilities",
  "/media-services/equipment-custom-clearance",
  "/media-services/filming-permits",
  "/media-services/prohibited-items",
  "/media-services/spectrum-policy",
  "/homepage",
  "/permits",
  "/media-services/equipment-clearance",
  "/news-services/albums",
  "/news-services/albums/[album_id]",
  "/news-services/albums/[album_id]/media/[media_id]",
  "/news-services/albums/[album_id]/media",
  "/equipment/application/[applicationId]/permit",
  "/bookings/media-events",
  "/bookings/interviews",
  "/bookings/studios",
  "/news-services/official-media-releases",
];

const Page = ({ children }) => {
  const router = useRouter();
  const isNotAuth = AuthRoutes.includes(router.pathname);

  if (!isNotAuth) return children;

  return <AuthHoc>{children}</AuthHoc>;
};

export default Page;

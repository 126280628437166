import { createContext, useState, useContext } from "react";

export const RegisterContext = createContext();

export const RegisterProvider = ({ children }) => {
  const [loginState, setLoginState] = useState({ step: null, data: null });

  return (
    <RegisterContext.Provider value={{ loginState, setLoginState }}>
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterData = () => useContext(RegisterContext);

import React, { useState } from "react";
import { useTranslation } from "next-i18next";

const SocialMedia = ({
  Color,
  textColor,
  display,
  colNumber,
  font,
  background,
  fontWeight,
  colMediaNumber,
}) => {
  const { t } = useTranslation("common");
  const [language, setLanguage] = useState("english");
  const switchLanguage = (e) => {
    setLanguage(e.target.id);
  };

  return (
    <div className={`${background} py-3`}>
      <div className="container">
        <div
          className={` socialMediaContainer d-flex justify-content-between align-items-center ${display}`}
        >
          <div className={`socialmedia ${textColor} ${font} ${fontWeight}`}>
            {t("multilingual-middlesocialmedia-links")}
          </div>
          <div
            className={`languages d-flex justify-content-around ${colNumber}`}
          >
            <a
              id="english"
              className={`${textColor} ${
                language == "english" ? "active" : ""
              } `}
              onClick={switchLanguage}
            >
              {t("english-language")}
            </a>
            <a
              id="arabic"
              className={`${textColor} ${
                language == "arabic" ? "active" : ""
              } `}
              onClick={switchLanguage}
            >
              {t("arabic-language")}
            </a>
            <a
              id="spanish"
              className={`${textColor} ${
                language == "spanish" ? "active" : ""
              } `}
              onClick={switchLanguage}
            >
              {t("spanish-language")}
            </a>
            <a
              id="chinese"
              className={`${textColor} ${
                language == "chinese" ? "active" : ""
              }`}
              onClick={switchLanguage}
            >
              {t("chinese-language")}
            </a>
          </div>
          <div className="smIconsWrapper">
            <div
              className={`smIcons ${colMediaNumber} d-flex align-items-center  
              } ${language == "english" ? "active" : ""}`}
            >
              <a
                href="https://www.facebook.com/roadto2022"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1040"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.855 30 30"
                  enable-background="new -0.77 -0.855 30 30"
                >
                  <path
                    id="Path_1953"
                    fill={`${Color}`}
                    d="M27.311,14.226c0-7.224-5.855-13.082-13.08-13.082c-7.225,0-13.081,5.856-13.081,13.08
                    c0,6.435,4.68,11.915,11.036,12.92v-9.142h-3.32v-3.78h3.32v-2.882c0-3.278,1.953-5.088,4.943-5.088
                    c0.98,0.014,1.959,0.1,2.928,0.256v3.216h-1.65c-1.033-0.137-1.984,0.589-2.123,1.625c-0.02,0.138-0.021,0.277-0.008,0.417v2.455
                    h3.627l-0.58,3.78h-3.049v9.144C22.629,26.141,27.309,20.661,27.311,14.226z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/roadto2022en/"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1039"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-1.222 -1.222 30 30"
                  enable-background="new -1.222 -1.222 30 30"
                >
                  <g>
                    <path
                      id="Path_1951"
                      fill={`${Color}`}
                      d="M13.776,3.119c3.474,0,3.884,0.015,5.255,0.076c0.824,0.011,1.642,0.162,2.414,0.449
                            c1.134,0.438,2.029,1.334,2.467,2.465c0.288,0.774,0.438,1.592,0.449,2.417c0.061,1.371,0.076,1.781,0.076,5.252
                            c0,3.475-0.016,3.882-0.076,5.253c-0.011,0.824-0.161,1.642-0.449,2.414c-0.438,1.134-1.333,2.029-2.467,2.467
                            c-0.771,0.287-1.59,0.438-2.414,0.449c-1.371,0.061-1.782,0.078-5.255,0.078c-3.472,0-3.879-0.018-5.25-0.078
                            C7.7,24.35,6.883,24.198,6.108,23.911c-1.13-0.438-2.026-1.333-2.465-2.467c-0.287-0.771-0.438-1.59-0.449-2.414
                            c-0.061-1.371-0.077-1.782-0.077-5.253c0-3.47,0.017-3.881,0.077-5.252c0.01-0.826,0.162-1.643,0.448-2.417
                            c0.438-1.131,1.334-2.027,2.466-2.466C6.883,3.355,7.7,3.204,8.525,3.194C9.895,3.133,10.305,3.119,13.776,3.119 M13.776,0.778
                            c-3.529,0-3.973,0.015-5.358,0.078C7.34,0.875,6.272,1.08,5.261,1.458C3.515,2.135,2.136,3.516,1.461,5.262
                            C1.083,6.27,0.877,7.339,0.857,8.416c-0.064,1.386-0.079,1.831-0.079,5.361c0,3.531,0.015,3.976,0.079,5.36
                            c0.02,1.078,0.225,2.146,0.604,3.155c0.676,1.745,2.055,3.126,3.801,3.802c1.01,0.378,2.077,0.583,3.156,0.604
                            c1.385,0.064,1.829,0.079,5.358,0.079c3.53,0,3.973-0.015,5.362-0.079c1.079-0.02,2.146-0.225,3.155-0.604
                            c1.745-0.676,3.127-2.057,3.803-3.802c0.378-1.01,0.583-2.077,0.604-3.155c0.063-1.386,0.077-1.829,0.077-5.36
                            c0-3.528-0.015-3.975-0.077-5.361c-0.021-1.078-0.226-2.143-0.604-3.154c-0.675-1.748-2.058-3.128-3.803-3.804
                            c-1.01-0.377-2.076-0.582-3.155-0.603C17.748,0.792,17.307,0.778,13.776,0.778z"
                    />
                    <path
                      id="Path_1952"
                      fill={`${Color}`}
                      d="M13.776,7.101c-3.685,0-6.673,2.99-6.672,6.675c0,3.686,2.989,6.675,6.674,6.674
                            c3.686,0,6.675-2.988,6.675-6.674S17.463,7.101,13.776,7.101z M13.776,18.11c-2.392-0.001-4.332-1.942-4.332-4.336
                            c0.001-2.393,1.942-4.332,4.334-4.332c2.393,0.001,4.333,1.941,4.333,4.334C18.11,16.169,16.168,18.11,13.776,18.11L13.776,18.11z"
                    />
                    <circle
                      id="Ellipse_13"
                      fill={`${Color}`}
                      cx="20.716"
                      cy="6.837"
                      r="1.56"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://twitter.com/roadto2022En"
                target="_blank"
                className="mx-1"
              >
                <svg
                  id="Group_1041"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.77 30 30"
                  enable-background="new -0.77 -0.77 30 30"
                >
                  <path
                    id="Path_1954"
                    fill={`${Color}`}
                    d="M14.23,1.23c-7.179,0-13,5.821-13,13c0,7.18,5.821,13,13,13c7.18,0,13-5.82,13-13
                        c0.001-7.179-5.819-13-12.998-13C14.231,1.23,14.23,1.23,14.23,1.23z M20.759,11.766c0.006,0.133,0.011,0.269,0.011,0.405
                        c0.001,4.923-3.988,8.914-8.911,8.916c-1.705,0-3.375-0.487-4.812-1.407c1.657,0.195,3.326-0.271,4.642-1.299
                        c-1.341-0.025-2.518-0.899-2.929-2.175c0.194,0.035,0.393,0.055,0.591,0.055c0.277-0.001,0.554-0.038,0.822-0.11
                        c-1.462-0.295-2.515-1.58-2.515-3.072v-0.041c0.435,0.243,0.922,0.378,1.42,0.392c-1.377-0.919-1.803-2.752-0.971-4.184
                        c1.592,1.958,3.94,3.147,6.459,3.273c-0.054-0.233-0.081-0.473-0.081-0.713c0-1.732,1.404-3.137,3.137-3.137
                        c0.867,0,1.694,0.359,2.288,0.99c0.701-0.138,1.374-0.395,1.988-0.759c-0.233,0.727-0.724,1.343-1.377,1.735
                        c0.62-0.073,1.228-0.239,1.799-0.493C21.898,10.772,21.37,11.321,20.759,11.766z"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCGr_NBOWno4FyhHY6gWeAng"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0.523 -3.357 30 30"
                  enable-background="new 0.523 -3.357 30 30"
                >
                  <path
                    id="Path_1955"
                    fill={`${Color}`}
                    d="M28.588,4.612c-0.283-1.23-1.202-2.214-2.41-2.584c-2.125-0.61-10.653-0.61-10.653-0.61
                    s-8.527,0-10.653,0.61C3.664,2.399,2.747,3.383,2.459,4.612C1.7,9.27,1.7,14.02,2.459,18.678c0.289,1.227,1.205,2.209,2.41,2.58
                    c2.126,0.609,10.653,0.609,10.653,0.609s8.527,0,10.655-0.609c1.206-0.371,2.123-1.354,2.41-2.582c0.76-4.658,0.76-9.408,0-14.067
                    L28.588,4.612z M12.736,15.961V7.328l7.125,4.316L12.736,15.961z"
                  />
                </svg>
              </a>
              <span className={`roadto pt-1 ${textColor}`} dir="auto">
                @roadto2022
              </span>
            </div>
            <div
              className={`smIcons d-flex align-items-center justify-content-center arabic 
              } ${language == "arabic" ? "active" : ""} 
              }`}
            >
              <a
                href="https://www.facebook.com/roadto2022"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1040"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.855 30 30"
                  enable-background="new -0.77 -0.855 30 30"
                >
                  <path
                    id="Path_1953"
                    fill={`${Color}`}
                    d="M27.311,14.226c0-7.224-5.855-13.082-13.08-13.082c-7.225,0-13.081,5.856-13.081,13.08
                    c0,6.435,4.68,11.915,11.036,12.92v-9.142h-3.32v-3.78h3.32v-2.882c0-3.278,1.953-5.088,4.943-5.088
                    c0.98,0.014,1.959,0.1,2.928,0.256v3.216h-1.65c-1.033-0.137-1.984,0.589-2.123,1.625c-0.02,0.138-0.021,0.277-0.008,0.417v2.455
                    h3.627l-0.58,3.78h-3.049v9.144C22.629,26.141,27.309,20.661,27.311,14.226z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/roadto2022/"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1039"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-1.222 -1.222 30 30"
                  enable-background="new -1.222 -1.222 30 30"
                >
                  <g>
                    <path
                      id="Path_1951"
                      fill={`${Color}`}
                      d="M13.776,3.119c3.474,0,3.884,0.015,5.255,0.076c0.824,0.011,1.642,0.162,2.414,0.449
                            c1.134,0.438,2.029,1.334,2.467,2.465c0.288,0.774,0.438,1.592,0.449,2.417c0.061,1.371,0.076,1.781,0.076,5.252
                            c0,3.475-0.016,3.882-0.076,5.253c-0.011,0.824-0.161,1.642-0.449,2.414c-0.438,1.134-1.333,2.029-2.467,2.467
                            c-0.771,0.287-1.59,0.438-2.414,0.449c-1.371,0.061-1.782,0.078-5.255,0.078c-3.472,0-3.879-0.018-5.25-0.078
                            C7.7,24.35,6.883,24.198,6.108,23.911c-1.13-0.438-2.026-1.333-2.465-2.467c-0.287-0.771-0.438-1.59-0.449-2.414
                            c-0.061-1.371-0.077-1.782-0.077-5.253c0-3.47,0.017-3.881,0.077-5.252c0.01-0.826,0.162-1.643,0.448-2.417
                            c0.438-1.131,1.334-2.027,2.466-2.466C6.883,3.355,7.7,3.204,8.525,3.194C9.895,3.133,10.305,3.119,13.776,3.119 M13.776,0.778
                            c-3.529,0-3.973,0.015-5.358,0.078C7.34,0.875,6.272,1.08,5.261,1.458C3.515,2.135,2.136,3.516,1.461,5.262
                            C1.083,6.27,0.877,7.339,0.857,8.416c-0.064,1.386-0.079,1.831-0.079,5.361c0,3.531,0.015,3.976,0.079,5.36
                            c0.02,1.078,0.225,2.146,0.604,3.155c0.676,1.745,2.055,3.126,3.801,3.802c1.01,0.378,2.077,0.583,3.156,0.604
                            c1.385,0.064,1.829,0.079,5.358,0.079c3.53,0,3.973-0.015,5.362-0.079c1.079-0.02,2.146-0.225,3.155-0.604
                            c1.745-0.676,3.127-2.057,3.803-3.802c0.378-1.01,0.583-2.077,0.604-3.155c0.063-1.386,0.077-1.829,0.077-5.36
                            c0-3.528-0.015-3.975-0.077-5.361c-0.021-1.078-0.226-2.143-0.604-3.154c-0.675-1.748-2.058-3.128-3.803-3.804
                            c-1.01-0.377-2.076-0.582-3.155-0.603C17.748,0.792,17.307,0.778,13.776,0.778z"
                    />
                    <path
                      id="Path_1952"
                      fill={`${Color}`}
                      d="M13.776,7.101c-3.685,0-6.673,2.99-6.672,6.675c0,3.686,2.989,6.675,6.674,6.674
                            c3.686,0,6.675-2.988,6.675-6.674S17.463,7.101,13.776,7.101z M13.776,18.11c-2.392-0.001-4.332-1.942-4.332-4.336
                            c0.001-2.393,1.942-4.332,4.334-4.332c2.393,0.001,4.333,1.941,4.333,4.334C18.11,16.169,16.168,18.11,13.776,18.11L13.776,18.11z"
                    />
                    <circle
                      id="Ellipse_13"
                      fill={`${Color}`}
                      cx="20.716"
                      cy="6.837"
                      r="1.56"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://twitter.com/roadto2022"
                target="_blank"
                className="mx-1"
              >
                <svg
                  id="Group_1041"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.77 30 30"
                  enable-background="new -0.77 -0.77 30 30"
                >
                  <path
                    id="Path_1954"
                    fill={`${Color}`}
                    d="M14.23,1.23c-7.179,0-13,5.821-13,13c0,7.18,5.821,13,13,13c7.18,0,13-5.82,13-13
                        c0.001-7.179-5.819-13-12.998-13C14.231,1.23,14.23,1.23,14.23,1.23z M20.759,11.766c0.006,0.133,0.011,0.269,0.011,0.405
                        c0.001,4.923-3.988,8.914-8.911,8.916c-1.705,0-3.375-0.487-4.812-1.407c1.657,0.195,3.326-0.271,4.642-1.299
                        c-1.341-0.025-2.518-0.899-2.929-2.175c0.194,0.035,0.393,0.055,0.591,0.055c0.277-0.001,0.554-0.038,0.822-0.11
                        c-1.462-0.295-2.515-1.58-2.515-3.072v-0.041c0.435,0.243,0.922,0.378,1.42,0.392c-1.377-0.919-1.803-2.752-0.971-4.184
                        c1.592,1.958,3.94,3.147,6.459,3.273c-0.054-0.233-0.081-0.473-0.081-0.713c0-1.732,1.404-3.137,3.137-3.137
                        c0.867,0,1.694,0.359,2.288,0.99c0.701-0.138,1.374-0.395,1.988-0.759c-0.233,0.727-0.724,1.343-1.377,1.735
                        c0.62-0.073,1.228-0.239,1.799-0.493C21.898,10.772,21.37,11.321,20.759,11.766z"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCGr_NBOWno4FyhHY6gWeAng"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0.523 -3.357 30 30"
                  enable-background="new 0.523 -3.357 30 30"
                >
                  <path
                    id="Path_1955"
                    fill={`${Color}`}
                    d="M28.588,4.612c-0.283-1.23-1.202-2.214-2.41-2.584c-2.125-0.61-10.653-0.61-10.653-0.61
                    s-8.527,0-10.653,0.61C3.664,2.399,2.747,3.383,2.459,4.612C1.7,9.27,1.7,14.02,2.459,18.678c0.289,1.227,1.205,2.209,2.41,2.58
                    c2.126,0.609,10.653,0.609,10.653,0.609s8.527,0,10.655-0.609c1.206-0.371,2.123-1.354,2.41-2.582c0.76-4.658,0.76-9.408,0-14.067
                    L28.588,4.612z M12.736,15.961V7.328l7.125,4.316L12.736,15.961z"
                  />
                </svg>
              </a>
              <span className={`roadto ${textColor} pt-1`} dir="auto">
                @roadto2022ar
              </span>
            </div>
            <div
              className={`smIcons d-flex align-items-center justify-content-center spanish 
              } ${language == "spanish" ? "active" : ""} 
              }`}
            >
              <a
                href="https://www.facebook.com/roadto2022"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1040"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.855 30 30"
                  enable-background="new -0.77 -0.855 30 30"
                >
                  <path
                    id="Path_1953"
                    fill={`${Color}`}
                    d="M27.311,14.226c0-7.224-5.855-13.082-13.08-13.082c-7.225,0-13.081,5.856-13.081,13.08
                    c0,6.435,4.68,11.915,11.036,12.92v-9.142h-3.32v-3.78h3.32v-2.882c0-3.278,1.953-5.088,4.943-5.088
                    c0.98,0.014,1.959,0.1,2.928,0.256v3.216h-1.65c-1.033-0.137-1.984,0.589-2.123,1.625c-0.02,0.138-0.021,0.277-0.008,0.417v2.455
                    h3.627l-0.58,3.78h-3.049v9.144C22.629,26.141,27.309,20.661,27.311,14.226z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/roadto2022es"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  id="Group_1039"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-1.222 -1.222 30 30"
                  enable-background="new -1.222 -1.222 30 30"
                >
                  <g>
                    <path
                      id="Path_1951"
                      fill={`${Color}`}
                      d="M13.776,3.119c3.474,0,3.884,0.015,5.255,0.076c0.824,0.011,1.642,0.162,2.414,0.449
                            c1.134,0.438,2.029,1.334,2.467,2.465c0.288,0.774,0.438,1.592,0.449,2.417c0.061,1.371,0.076,1.781,0.076,5.252
                            c0,3.475-0.016,3.882-0.076,5.253c-0.011,0.824-0.161,1.642-0.449,2.414c-0.438,1.134-1.333,2.029-2.467,2.467
                            c-0.771,0.287-1.59,0.438-2.414,0.449c-1.371,0.061-1.782,0.078-5.255,0.078c-3.472,0-3.879-0.018-5.25-0.078
                            C7.7,24.35,6.883,24.198,6.108,23.911c-1.13-0.438-2.026-1.333-2.465-2.467c-0.287-0.771-0.438-1.59-0.449-2.414
                            c-0.061-1.371-0.077-1.782-0.077-5.253c0-3.47,0.017-3.881,0.077-5.252c0.01-0.826,0.162-1.643,0.448-2.417
                            c0.438-1.131,1.334-2.027,2.466-2.466C6.883,3.355,7.7,3.204,8.525,3.194C9.895,3.133,10.305,3.119,13.776,3.119 M13.776,0.778
                            c-3.529,0-3.973,0.015-5.358,0.078C7.34,0.875,6.272,1.08,5.261,1.458C3.515,2.135,2.136,3.516,1.461,5.262
                            C1.083,6.27,0.877,7.339,0.857,8.416c-0.064,1.386-0.079,1.831-0.079,5.361c0,3.531,0.015,3.976,0.079,5.36
                            c0.02,1.078,0.225,2.146,0.604,3.155c0.676,1.745,2.055,3.126,3.801,3.802c1.01,0.378,2.077,0.583,3.156,0.604
                            c1.385,0.064,1.829,0.079,5.358,0.079c3.53,0,3.973-0.015,5.362-0.079c1.079-0.02,2.146-0.225,3.155-0.604
                            c1.745-0.676,3.127-2.057,3.803-3.802c0.378-1.01,0.583-2.077,0.604-3.155c0.063-1.386,0.077-1.829,0.077-5.36
                            c0-3.528-0.015-3.975-0.077-5.361c-0.021-1.078-0.226-2.143-0.604-3.154c-0.675-1.748-2.058-3.128-3.803-3.804
                            c-1.01-0.377-2.076-0.582-3.155-0.603C17.748,0.792,17.307,0.778,13.776,0.778z"
                    />
                    <path
                      id="Path_1952"
                      fill={`${Color}`}
                      d="M13.776,7.101c-3.685,0-6.673,2.99-6.672,6.675c0,3.686,2.989,6.675,6.674,6.674
                            c3.686,0,6.675-2.988,6.675-6.674S17.463,7.101,13.776,7.101z M13.776,18.11c-2.392-0.001-4.332-1.942-4.332-4.336
                            c0.001-2.393,1.942-4.332,4.334-4.332c2.393,0.001,4.333,1.941,4.333,4.334C18.11,16.169,16.168,18.11,13.776,18.11L13.776,18.11z"
                    />
                    <circle
                      id="Ellipse_13"
                      fill={`${Color}`}
                      cx="20.716"
                      cy="6.837"
                      r="1.56"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://twitter.com/roadto2022es"
                target="_blank"
                className="mx-1"
              >
                <svg
                  id="Group_1041"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="-0.77 -0.77 30 30"
                  enable-background="new -0.77 -0.77 30 30"
                >
                  <path
                    id="Path_1954"
                    fill={`${Color}`}
                    d="M14.23,1.23c-7.179,0-13,5.821-13,13c0,7.18,5.821,13,13,13c7.18,0,13-5.82,13-13
                        c0.001-7.179-5.819-13-12.998-13C14.231,1.23,14.23,1.23,14.23,1.23z M20.759,11.766c0.006,0.133,0.011,0.269,0.011,0.405
                        c0.001,4.923-3.988,8.914-8.911,8.916c-1.705,0-3.375-0.487-4.812-1.407c1.657,0.195,3.326-0.271,4.642-1.299
                        c-1.341-0.025-2.518-0.899-2.929-2.175c0.194,0.035,0.393,0.055,0.591,0.055c0.277-0.001,0.554-0.038,0.822-0.11
                        c-1.462-0.295-2.515-1.58-2.515-3.072v-0.041c0.435,0.243,0.922,0.378,1.42,0.392c-1.377-0.919-1.803-2.752-0.971-4.184
                        c1.592,1.958,3.94,3.147,6.459,3.273c-0.054-0.233-0.081-0.473-0.081-0.713c0-1.732,1.404-3.137,3.137-3.137
                        c0.867,0,1.694,0.359,2.288,0.99c0.701-0.138,1.374-0.395,1.988-0.759c-0.233,0.727-0.724,1.343-1.377,1.735
                        c0.62-0.073,1.228-0.239,1.799-0.493C21.898,10.772,21.37,11.321,20.759,11.766z"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCGr_NBOWno4FyhHY6gWeAng"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0.523 -3.357 30 30"
                  enable-background="new 0.523 -3.357 30 30"
                >
                  <path
                    id="Path_1955"
                    fill={`${Color}`}
                    d="M28.588,4.612c-0.283-1.23-1.202-2.214-2.41-2.584c-2.125-0.61-10.653-0.61-10.653-0.61
                    s-8.527,0-10.653,0.61C3.664,2.399,2.747,3.383,2.459,4.612C1.7,9.27,1.7,14.02,2.459,18.678c0.289,1.227,1.205,2.209,2.41,2.58
                    c2.126,0.609,10.653,0.609,10.653,0.609s8.527,0,10.655-0.609c1.206-0.371,2.123-1.354,2.41-2.582c0.76-4.658,0.76-9.408,0-14.067
                    L28.588,4.612z M12.736,15.961V7.328l7.125,4.316L12.736,15.961z"
                  />
                </svg>
              </a>
              <span className={`roadto ${textColor} pt-1`} dir="auto">
                @roadto2022
              </span>
            </div>
            <div
              className={`smIcons d-flex align-items-center justify-content-center chinese 
              } ${language == "chinese" ? "active" : ""} 
              }`}
            >
              <a
                href="https://mp.weixin.qq.com/s/CQ464q-Me5Mf18Lq8SBjfA"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 160.3 30"
                  enable-background="new 0 0 160.3 30"
                  width="107px"
                  height="20px"
                >
                  <path
                    fill={`${Color}`}
                    d="M15.4,14.2c1.8-2.2,4.3-3.6,7.1-3.8c0.7-0.1,1.4-0.1,2.2-0.2c-0.1-0.2-0.1-0.5-0.2-0.7
	c-0.6-2.1-1.9-3.8-3.7-5.1c-2.6-1.8-5.7-2.6-8.8-2.3C9.5,2.5,6.9,3.6,5,5.4c-3.1,2.6-3.8,7-1.8,10.5c0.7,1.2,1.6,2.2,2.7,3
	c0.4,0.2,0.5,0.7,0.4,1.1c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2,0,0.4,0.2,0.6c0.1,0.1,0.4,0,0.6-0.1c0.7-0.4,1.5-0.8,2.2-1.3
	c0.4-0.2,0.9-0.3,1.3-0.2c0.8,0.2,1.6,0.3,2.3,0.4c0.6,0,1.2,0,1.8,0c0-0.2,0-0.3-0.1-0.4C13.4,18.6,13.9,16.1,15.4,14.2z M17.3,7.3
	c0.8,0,1.4,0.7,1.4,1.5s-0.7,1.4-1.5,1.4s-1.4-0.7-1.4-1.4C15.8,7.9,16.5,7.3,17.3,7.3z M9.7,10.2c-0.8,0-1.5-0.6-1.5-1.4
	s0.6-1.5,1.4-1.5c0.8,0,1.5,0.6,1.5,1.4S10.5,10.2,9.7,10.2z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M25.2,11c-3-0.4-6,0.6-8.2,2.6c-1.6,1.4-2.5,3.5-2.4,5.7c0.1,2.1,1.1,4,2.8,5.3c2.7,2.2,6.2,2.9,9.5,2
	c0.5-0.2,1.1-0.1,1.5,0.2c0.5,0.3,1,0.6,1.5,0.9c0.2,0.1,0.4,0.1,0.6,0.1c0-0.2,0.1-0.4,0.1-0.6c-0.1-0.4-0.2-0.8-0.3-1.1
	c-0.2-0.5,0-1.1,0.4-1.3c1.8-1.3,2.9-3.4,3-5.7c0-1.9-0.8-3.8-2.1-5.1C29.9,12.2,27.6,11.2,25.2,11z M20.9,17.6
	c-0.7,0-1.1-0.6-1.1-1.3s0.6-1.2,1.3-1.1h0.1c0.6,0.1,1,0.6,1,1.4C22.1,17.2,21.6,17.7,20.9,17.6z M27.3,17.6
	c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2C28.5,17.1,27.9,17.6,27.3,17.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M50.9,10.7h-5.7c4.1-1.9,5.5-3.6,5.5-5.6c0-1.8-1.3-3.1-3.9-3.1c-1.2,0-2.4,0.2-3.5,0.6
	c0,0.7,0.1,1.5,0.3,2.2c0.9-0.4,1.9-0.6,2.8-0.6c1,0,1.6,0.5,1.6,1.4c0,1.4-1.2,2.6-4.9,5.3V13h7.8C50.9,13,50.9,10.7,50.9,10.7z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M56.8,13.1c2.8,0,4.6-2,4.6-5.6C61.4,4,59.7,2,56.8,2s-4.6,2-4.6,5.6S54,13.1,56.8,13.1z M56.8,4.2
	c1.4,0,1.9,1.3,1.9,3.4S58.2,11,56.8,11s-1.9-1.3-1.9-3.4C54.9,5.5,55.4,4.2,56.8,4.2z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M70.4,10.7h-5.6c4.1-1.9,5.5-3.6,5.5-5.6C70.2,3.4,69,2,66.3,2c-1.2,0-2.4,0.2-3.5,0.6
	c0,0.7,0.1,1.5,0.3,2.2C64,4.4,65,4.2,65.9,4.2c1,0,1.6,0.5,1.6,1.4c0,1.4-1.2,2.6-4.9,5.3V13h7.8V10.7z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M79.6,10.7h-5.7c4.1-1.9,5.5-3.6,5.5-5.6c0-1.8-1.3-3.1-3.9-3.1c-1.2,0-2.4,0.2-3.5,0.6
	c0,0.7,0.1,1.5,0.3,2.2c0.9-0.4,1.9-0.6,2.8-0.6c1,0,1.6,0.5,1.6,1.4c0,1.4-1.2,2.6-4.9,5.3V13h7.8C79.6,13,79.6,10.7,79.6,10.7z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M90.6,7.8h-2.3c0.3,0.7,0.8,1.6,1.5,2.7c0.4,0.8,1.6,1.2,3.7,1.1C92.2,10.6,91.2,9.3,90.6,7.8z"
                  />
                  <polygon
                    fill={`${Color}`}
                    points="85.9,13.1 88,13.1 88,7 93.3,7 93.3,6.5 88,6.5 88,4.3 92.4,4.3 92.4,3.8 88,3.8 88,1.6 85.9,1.6 
	85.9,6.5 82,6.5 82,7 85.9,7 "
                  />
                  <path
                    fill={`${Color}`}
                    d="M103.4,4.8c-1,0-1.6,0.2-1.9,0.7c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4h-1.9c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.2,0.6-0.5,1.2-0.8,1.8c-0.2,0.2-0.3,0.4-0.5,0.6c0.6,0.1,1.3,0,1.8-0.2v0.1h4.3c0.7,0.3,1.4,0.3,2.1,0.2c-0.6-0.5-1-1.3-1.2-2
	C105.2,5.1,104.5,4.8,103.4,4.8z M100.7,7.3c0.2-0.2,0.3-0.4,0.4-0.6c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.3,0.1-0.4
	c0.3-0.3,0.7-0.4,1.1-0.4c0.6,0,0.9,0.3,1.1,1.1c0.1,0.3,0.2,0.5,0.4,0.7C103.9,7.3,100.7,7.3,100.7,7.3z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M100.8,12.9v-0.6c0.1,0.4,0.4,0.6,0.9,0.6h2.6c1.1,0,1.6-0.4,1.6-1.2V9.8c0-0.7-0.5-1.1-1.5-1.1h-2.5
	c-0.6,0-0.9,0.2-1.1,0.6V8.8h-2v2.3v1.8C98.8,12.9,100.8,12.9,100.8,12.9z M100.8,9.6c0.2-0.3,0.5-0.4,0.9-0.3h1.4
	c0.6,0,0.9,0.2,0.8,0.8v1.6c0,0.6-0.3,0.8-0.9,0.8h-1.5c-0.3,0-0.6-0.1-0.7-0.4C100.8,12.1,100.8,9.6,100.8,9.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M98.8,11.1c-0.3,0.2-0.7,0.5-1.2,0.6V4.6h0.8V4.2h-0.8V1.5h-1.8v2.6h-0.9v0.4h0.9v7.4c-0.3,0-0.6,0-1,0v0.5
	C97.2,12.5,98.5,12,98.8,11.1z"
                  />
                  <polygon
                    fill={`${Color}`}
                    points="105,1.5 103.2,1.5 103.2,2.8 101.4,2.8 101.4,1.5 99.6,1.5 99.6,2.8 98.1,2.8 98.1,3.4 99.6,3.4 
	99.6,4.5 101.4,4.5 101.4,3.4 103.2,3.4 103.2,4.5 105,4.5 105,3.4 106.5,3.4 106.5,2.8 105,2.8 "
                  />
                  <path
                    fill={`${Color}`}
                    d="M115.6,6.3c0.2,1.6,0.6,3.1,1,4.7c0.1,0.9,1.1,1.4,2.9,1.3c-0.6-0.4-1.2-2.4-1.8-6H115.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M109.7,6.3c-0.6,3.6-1.2,5.6-1.8,6c1.7,0,2.6-0.4,2.8-1.3c0.4-1.5,0.7-3.1,1-4.7H109.7z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M113.2,13.1c1.1,0,1.6-0.5,1.6-1.5V4.2h-2V11c0,0.6-0.3,1-0.9,0.9c-0.4,0-0.9-0.1-1.3-0.2
	C111.1,12.6,112.1,13.2,113.2,13.1z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M111.1,4.4c0-0.1,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0.5-0.4,1.1-0.6,1.8-0.6h2.7c0.7,0,1.1,0.2,1.1,0.7
	c0.1,0.7-0.1,1.4-0.6,1.8h2.3c0.3-0.6,0.5-1.2,0.4-1.9c0-0.8-0.5-1.2-1.6-1.2h-4c-0.7-0.1-1.3,0.2-1.8,0.6c0-0.1,0.1-0.2,0.1-0.3
	c0.2-0.4,0.3-0.9,0.4-1.4H110c-0.7,2.3-1.4,3.7-2.2,4.2C109.9,5.9,111,5.4,111.1,4.4z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M130.9,1.7h-2v3.1h-1.5V1.7h-2v3.1H124v-3h-2v3h-1.2v0.5h1.2v5.8c-0.1,1.2,0.4,1.7,1.7,1.6h8.3v-0.5h-6.6
	c-1,0.1-1.4-0.4-1.3-1.4V5.3h1.4v5.3h2V10c0.1,0.5,0.4,0.7,1,0.7h0.6c1.3,0,1.9-0.4,1.8-1.3v-4h1.5V4.8h-1.5L130.9,1.7L130.9,1.7z
	 M129,9.4L129,9.4c-0.1,0.5-0.3,0.8-0.6,0.8H128c-0.3,0-0.5-0.2-0.5-0.4V5.3h1.5V9.4z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M136.5,5.5c-0.1-0.1-0.1-0.2-0.1-0.3V4.3h2.2v1.5h-1.5C136.8,5.8,136.6,5.7,136.5,5.5c0,0.6,0.5,0.9,0.9,0.9
	h5.8c1,0.1,1.5-0.4,1.4-1.4V3.3c0.1-0.9-0.4-1.4-1.5-1.3h-5.5c-0.5-0.1-1,0.3-1.1,0.7V2h-1.9v4.5h1.9L136.5,5.5L136.5,5.5z
	 M140.4,2.5h1.2c0.7,0,1,0.2,1,0.7v0.6h-2.2V2.5z M140.4,4.3h2.2V5c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.4,0.6-0.8,0.6h-1.4V4.3z M136.4,3
	c0.2-0.3,0.5-0.5,0.8-0.5h1.4v1.4h-2.2V3z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M136.5,5.5L136.5,5.5L136.5,5.5L136.5,5.5z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M143.7,8.1c-0.6-0.9-1.7-1.3-3.4-1.2h-0.5c-0.6,0-1.1,0.1-1.6,0.4c0.1-0.1,0.2-0.3,0.3-0.4h-1.9
	c-0.7,1.2-1.7,2.3-2.9,3c0.8,0.1,1.7,0,2.5-0.2V11c0,0.8-0.4,1.6-1,2.2c2,0.1,3-0.6,2.9-2.1V8.5h-0.5V8.4c0.1-0.2,0.2-0.3,0.3-0.5
	c0.4-0.3,0.9-0.5,1.5-0.5h0.5c0.8,0,1.5,0.4,1.9,1.1H141v4.6h1.9V9.7c0.8,0.3,1.7,0.4,2.6,0.3C144.8,9.5,144.2,8.8,143.7,8.1z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M156,2.5h2.3V2.1H152v0.4h2.3c-0.1,0.3-0.2,0.7-0.4,1.3c-0.6,1.9-1.2,3.5-1.7,4.9c0,0.1-0.1,0.3-0.1,0.4v-3
	c0.1-0.8-0.5-1.2-1.7-1.1h-0.1V3.7h1.9V3.2h-1.9V1.5h-1.7v1.7h-2.1v0.4h2.1V5c-0.2,0.1-0.4,0.3-0.4,0.5V5h-1.4v5.7
	c0,0.4-0.1,0.7-0.3,1c1.2,0.1,1.8-0.3,1.7-1.3V5.8l0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2v7.6h1.7V5.5c0.3,0.1,0.5,0.3,0.4,0.7v4.4
	c-0.1,0.8,0.5,1.2,1.7,1.2c-0.3-0.1-0.4-0.5-0.3-1.1v-0.2c0.7,0.1,1.3-0.3,1.5-0.9c0.1-0.4,0.2-0.7,0.4-1.1c0.1-0.3,0.2-0.6,0.2-0.7
	v5.4h1.6V5.9c0.2,1,0.4,2.1,0.6,3.2c0.1,1.1,0.7,1.5,1.8,1.4c-0.1-0.5-0.3-1-0.3-1.5c-0.4-2-0.8-3.7-1-5.2h-1.3
	c0-0.1,0.1-0.2,0.1-0.4C155.8,3,155.9,2.8,156,2.5z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M50.8,24.1c0.9-0.9,1.6-1.5,1.8-1.8h-2.4c-0.7,0.7-1.3,1.3-1.9,1.8c-0.6-0.6-1.2-1.2-1.9-1.8H44
	c0.5,0.5,1.5,1.5,3.1,3c-1.3,1.2-2.8,2.3-4.3,3.2c1,0.1,2,0,3-0.2c0.7-0.2,1.3-0.6,1.8-1.2c0.3-0.2,0.6-0.4,0.8-0.6
	c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.1,0.1,0.1,0.1c0.5,0.5,1.1,0.9,1.8,1.2c1,0.2,2,0.3,3,0.2c-1.5-0.9-3-2-4.3-3.2L50.8,24.1z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M49,19.1c0.3,0.6,0.6,1.2,1,1.7c0.3,0.9,1.5,1.3,3.6,1.2c-1-0.8-1.8-1.8-2.4-2.9C51.2,19.1,49,19.1,49,19.1z
	"
                  />
                  <path
                    fill={`${Color}`}
                    d="M43,22c2.1,0.1,3.3-0.3,3.6-1.2c0.4-0.5,0.7-1.1,1-1.7h-2.2C44.8,20.2,43.9,21.2,43,22z"
                  />
                  <polygon
                    fill={`${Color}`}
                    points="53.8,18.2 49.3,18.2 49.3,16.8 47.2,16.8 47.2,18.2 42.8,18.2 42.8,18.7 53.8,18.7 "
                  />
                  <path
                    fill={`${Color}`}
                    d="M57.5,16.8v0.1c-0.8,3-1.5,4.7-2,5.2c0.4,0,0.7,0,1.1,0v6.4h2.1V20c0.4-1.3,0.7-2.4,0.9-3.2H57.5z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M66.2,16.9H64v2.2h-4.7v0.4H64v4.9c-0.5-0.3-0.8-0.8-1.1-1.2c-0.5-0.7-0.9-1.5-1.1-2.3h-2.2
	c0.4,1,0.8,1.9,1.3,2.8c0.4,0.8,1.4,1.1,3.1,0.8v1.7c0.1,0.7-0.4,1.1-1.4,1.1c-0.8,0-1.5-0.1-2.2-0.4c1,1.1,2.4,1.6,3.9,1.6
	c1.3,0,1.9-0.6,1.9-1.8v-7.1H67v-0.4h-0.8V16.9z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M72.8,17.1h-2.1c-0.2,1.7-0.3,2.9-0.3,3.8c0,0.8,0.5,1.2,1.7,1.2h4.2c0.9,0,1.3,0.4,1.2,1.1l-0.1,1.2h-8.9
	v0.5h8.8l-0.1,1.3c-0.1,0.8-0.6,1.2-1.7,1.2c-0.9,0-1.9-0.1-2.8-0.4c0.8,0.9,2.3,1.4,4.3,1.5c1.4-0.1,2.1-0.7,2.3-1.7l0.3-3.5
	c0.1-1.1-0.6-1.7-2-1.8h-4.3c-0.6,0-0.9-0.2-0.9-0.7c0-0.4,0-0.8,0.1-1.3v-0.1h7.4v-0.5h-7.4C72.6,18.3,72.7,17.7,72.8,17.1z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M88.8,19.6l0.1-0.4h3.6v-0.4h-3.4c0.3-0.7,0.6-1.4,0.8-1.9h-2.2c-0.1,0.3-0.3,0.7-0.5,1.2L87,18.7h-1.9v0.4
	h1.8c-0.3,0.5-0.5,1.1-0.6,1.6h-1.5v0.4H86c-0.1,0.4-0.2,0.7-0.2,1.1c0,0.6,0.4,1,1.4,0.9h3c0.4,0,0.6,0.1,0.5,0.3
	c-0.3,0.9-0.7,1.7-1.4,2.3H89c-0.4-0.5-0.8-0.9-1-1.2h-2.5c0.3,0.3,0.5,0.6,0.7,0.9c0.6,0.7,1.2,1.3,1.8,1.9
	c0.3,0.5,0.8,0.8,1.4,0.9c0.9,0.2,1.8,0.2,2.8,0.2c-1-0.6-1.9-1.4-2.7-2.3c1.3,0,2.1-0.3,2.3-0.8c0.3-0.4,0.5-0.8,0.7-1.2
	c0.4-0.8,0.1-1.3-0.9-1.3h-3c-0.5,0-0.7-0.1-0.7-0.4c0.1-0.4,0.2-0.8,0.3-1.2H93v-0.4h-4.6C88.5,20.3,88.7,19.9,88.8,19.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M83.1,16.8c-0.6,2.8-1.2,4.3-1.8,4.6h1v7h1.9v-8.3c0.1-0.1,0.1-0.2,0.1-0.3c0.4-0.9,0.6-1.9,0.9-3H83.1z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M104.6,23.2c0.4,0,1-0.4,1.1-1c0.2-0.5,0.4-1.1,0.5-1.7h-1.8c0,0.3-0.1,0.6-0.3,0.8c0-0.1,0-0.4,0-0.7
	c0-0.2-0.1-0.4-0.1-0.6h-1.8c0.1,0.1,0.1,0.3,0.1,0.4h-1v-0.1c2.3-1.3,3.3-2.1,3.1-2.5c0-0.5-0.4-0.7-1.3-0.6h-7.5v0.5h5.6
	c1.4-0.1,1.3,0.2-0.2,1c-0.7,0.4-1.3,0.7-1.7,1v0.8h-1.5c-0.1-0.5-0.5-0.8-1.3-0.7h-2.2v0.5h1c0.5,0,0.7,0.1,0.7,0.5
	c-0.2,1.7-0.4,3.4-0.8,5.1c-0.1,0.7-0.4,1.4-0.9,1.9c1.7,0.1,2.6-0.4,2.7-1.4c0.4-1.8,0.7-3.6,0.9-5.4h1.5v1.5h-1.5V23h1.5v1.5h-1.7
	V25h1.7v1.8c0,0.4-0.2,0.5-0.7,0.5s-1-0.1-1.4-0.2c0.5,0.9,1.5,1.4,2.6,1.4c0.1,0,0.2,0,0.4,0c0.7-0.1,1.2-0.7,1.1-1.4V25h1.5v-0.5
	h-1.5V23h1.2v-0.5h-1.2V21h1.1c0.4,1.8,0.7,3.8,1.2,5.9c0.3,1.1,1.1,1.5,2.6,1.3c-0.4-0.4-0.7-0.9-0.9-1.5
	C105.1,25.6,104.8,24.4,104.6,23.2z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M116.4,18.2h-2.1v-1.4h-2.1v1.4h-0.6c-1.3-0.1-2,0.3-2.1,1.1v-1.1h-1.9v2.6h1.2V28h2v-0.6
	c0.1,0.4,0.5,0.6,0.8,0.5h5.2c1.1,0.1,1.6-0.4,1.6-1.3V26c0-0.1,0-0.2,0-0.4c-0.1-0.7-0.8-1.2-1.5-1.1H112c-0.6,0-1,0.2-1.2,0.7
	v-2.5c0.1,0.4,0.4,0.6,1,0.6h4.2c1.2,0,1.8-0.2,1.8-0.8v-1.2c0-0.2-0.1-0.4-0.2-0.6h1c0.1-0.3,0.2-0.6,0.2-1v-0.4
	C118.7,18.5,118,18.2,116.4,18.2z M111.4,25.1h4.2c0.5,0,0.7,0.3,0.6,1v0.7c0,0.6-0.2,0.9-0.6,0.9h-4.2c-0.3,0-0.5-0.2-0.7-0.6v-1.6
	C110.8,25.2,111.1,25,111.4,25.1z M114.7,22.8h-3.4c-0.3,0-0.6-0.2-0.7-0.4v-1.2c0.1-0.3,0.4-0.4,0.7-0.4h3.4c0.5,0,0.7,0.2,0.7,0.6
	v0.8h0.1C115.5,22.6,115.2,22.8,114.7,22.8z M116.5,20.3c-0.2,0-0.5-0.1-0.7,0h-4.1c-0.5-0.1-0.9,0.2-1.1,0.7v-0.5h-1.3v-0.9
	c0.4-0.6,1.1-0.9,1.8-0.8h4.5c0.7,0,0.9,0.1,0.9,0.5v0.4h0.1C116.6,19.9,116.6,20.1,116.5,20.3z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M127.1,16.9H125v2h-4.9v0.5h2.1c0,0.7-0.1,1.8-0.2,3.3c-0.1,0.7-0.1,1.2-0.1,1.5c0,1.5-0.6,3-1.7,4
	c1.7,0,2.7-0.2,3.2-0.7s0.9-1.7,0.9-3.5c0-0.3,0.1-0.6,0-0.9c0-0.4,0-0.7,0-0.8c0.3-0.3,0.8-0.4,1.2-0.4h1.5c1-0.1,1.4,0.3,1.3,1.2
	l-0.2,3.1c0,0.7-0.4,1.1-1.3,1.1c-0.9,0.1-1.9-0.1-2.7-0.5c0.6,1.1,1.9,1.7,3.8,1.7c0.2,0,0.4,0,0.7,0c1.1-0.2,1.9-1.2,1.8-2.3
	l0.1-2.7c0.2-1.3-0.7-2-2.5-1.9h-2.4c-0.5,0-1,0.2-1.2,0.6c0.1-1,0.1-1.2,0-0.3c0.1-1.1,0.1-1.9,0.1-2.3h7.1v-0.2v-0.5h-4.5
	L127.1,16.9L127.1,16.9z"
                  />
                </svg>
              </a>
              <a
                href="https://weibo.com/u/7396857384"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="23px"
                  height="20px"
                  viewBox="0 0 34.5 30"
                  enable-background="new 0 0 34.5 30"
                >
                  <g>
                    <path
                      fill={`${Color}`}
                      d="M24.1,15c-0.8-0.2-0.9-0.4-0.6-1.2c0.2-0.5,0.3-1.1,0.3-1.6c0.2-1.3-0.7-2.5-2-2.7c-0.2,0-0.5,0-0.7,0
		c-1,0.1-2,0.3-2.9,0.8c-0.5,0.2-1,0.5-1.6,0.7c-0.4,0.1-0.5,0-0.5-0.4c0.1-0.3,0.2-0.7,0.2-1c0.2-0.7,0.1-1.4-0.2-2.1
		c-0.5-0.9-1.6-1.3-2.6-1.2c-0.9,0.1-1.7,0.4-2.5,0.8c-2.3,1.2-4.3,2.9-5.9,5C4,13.7,3,15.5,2.4,17.5c-0.6,1.9-0.4,3.9,0.5,5.7
		c0.8,1.5,2.1,2.6,3.6,3.4c2,1,4.3,1.5,6.5,1.5c0.7-0.1,1.5-0.1,2.2-0.2c2.7-0.3,5.2-1.1,7.5-2.5c1.8-1,3.2-2.5,4.1-4.3
		c0.5-1,0.7-2.2,0.4-3.3C26.9,16.3,25.6,15.2,24.1,15z M22,22.1L22,22.1c-1,1.5-2.4,2.6-4,3.2c-1.7,0.7-3.6,1-5.4,0.9
		c-2,0-3.9-0.6-5.6-1.6c-0.8-0.5-1.5-1.2-2-2.1c-0.8-1.6-0.6-3.5,0.5-4.8c1-1.3,2.5-2.3,4.1-2.8c3.1-1.2,6.5-1.1,9.5,0.2
		c1.1,0.4,2,1.2,2.7,2.1C22.9,18.6,23,20.6,22,22.1z"
                    />
                    <path
                      fill={`${Color}`}
                      d="M13.3,16.5c-1.4,0-2.7,0.5-3.7,1.5c-1.6,1.4-1.9,3.8-0.5,5.4c0.4,0.5,0.9,0.8,1.5,1.1c2.3,1,5,0.3,6.6-1.7
		c1.2-1.6,1-3.8-0.5-5.1C15.6,16.9,14.5,16.5,13.3,16.5z M11.4,23.1L11.4,23.1c-0.8,0-1.4-0.6-1.4-1.3l0,0c0-0.9,0.7-1.6,1.6-1.6
		c0.8,0,1.4,0.6,1.4,1.3c0,0,0,0,0,0.1C12.9,22.4,12.2,23.1,11.4,23.1z M14.1,20.7L14.1,20.7c-0.3,0-0.6-0.2-0.6-0.5l0,0
		c0-0.4,0.3-0.6,0.7-0.6c0.3,0,0.6,0.2,0.6,0.5l0,0C14.7,20.4,14.4,20.7,14.1,20.7z"
                    />
                    <path
                      fill={`${Color}`}
                      d="M29.3,4.4c-2.7-2.8-6.9-2.4-7-2.4c-0.6,0-1.1,0.6-1.1,1.2s0.6,1.1,1.2,1.1l0,0c0,0,3.2-0.2,5.2,1.7
		c1.3,1.3,1.8,3.3,1.6,6c-0.1,0.6,0.4,1.2,1.1,1.3h0.1c0.6,0,1.1-0.5,1.2-1.1C31.8,8.9,31.1,6.2,29.3,4.4z"
                    />
                    <path
                      fill={`${Color}`}
                      d="M22.9,6.1c-0.5,0-0.9,0.5-0.9,1S22.5,8,23,8c0.8,0,1.5,0.2,2.1,0.7c0.6,0.7,0.8,1.7,0.5,2.6
		c-0.1,0.5,0.3,1,0.8,1.1l0,0h0.1c0.5,0,0.9-0.4,0.9-0.8c0.3-1.5-0.1-3-1-4.1C25.5,6.5,24.2,6,22.9,6.1z"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://v.douyin.com/dRLGomM/"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="17px"
                  height="20px"
                  viewBox="0 0 25.7 30"
                  enable-background="new 0 0 25.7 30"
                >
                  <path
                    id="Path_2469"
                    fill={`${Color}`}
                    d="M24.7,12.7c-2.4,0-4.7-0.8-6.7-2.1c0,3.2,0,6.3,0,9.5c0.4,7.2-9,11.6-14.3,6.8
	c-6.8-5.3-1.6-17,6.9-15.4v4.7c-3.9-1.6-7.2,3.9-3.9,6.6c2.1,2.1,6.1,0.7,6.4-2.3c0.1-6.3,0-12.7,0-19h5c0,3.5,3,6.6,6.5,6.5
	L24.7,12.7z"
                  />
                </svg>
              </a>

              <a
                href="https://v.kuaishou.com/9sPAda"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="18px"
                  height="20px"
                  viewBox="0 0 27 30"
                  enable-background="new 0 0 27 30"
                >
                  <path
                    id="Path_2477"
                    fill={`${Color}`}
                    d="M8.6,26.2C7.4,26.8,6.2,27.4,5,28c-0.4,0.2-0.9,0.4-1.3,0.5c-1.3,0.2-2.5-0.7-2.7-2
	c0-0.1,0-0.2,0-0.3c0-2.4-0.1-4.8,0-7.2c0-1.3,1.1-2.4,2.4-2.3c0.4,0,0.7,0.1,1.1,0.3c1.3,0.6,2.5,1.2,3.7,1.9
	C8.3,19,8.5,19,8.7,19.1c0.1-0.1,0.2-0.3,0.2-0.4c0.8-1.8,2.7-3,4.7-2.9c2.4,0,4.8,0,7.2,0c2.6,0.1,4.7,2.1,4.8,4.7c0,1.5,0,3,0,4.4
	c-0.1,2.6-2.2,4.6-4.8,4.7c-2.5,0-4.9,0-7.4,0c-2,0-3.8-1.1-4.5-2.9C8.8,26.4,8.7,26.3,8.6,26.2z M17,18.3L17,18.3
	c-1.1,0-2.2,0-3.3,0c-1.3-0.2-2.5,0.8-2.7,2.1c0,0.2,0,0.4,0,0.6c0,1.1,0,2.3,0,3.4c-0.1,1.3,0.9,2.4,2.2,2.5c0.1,0,0.1,0,0.2,0
	c2.3,0,4.7,0,7,0c1.3,0,2.3-1.1,2.4-2.3c0-1.3,0-2.6,0-3.8c0-1.3-1-2.3-2.3-2.4h-0.1C19.3,18.3,18.2,18.3,17,18.3L17,18.3z
	 M3.5,22.6L3.5,22.6c0,0.7,0,1.3,0,2s0.4,1,1,0.7c1.2-0.6,2.3-1.2,3.5-1.7c0.3-0.1,0.4-0.4,0.4-0.7c0-0.4,0.2-0.8-0.3-1.1
	c-1.2-0.6-2.4-1.2-3.6-1.8c-0.6-0.3-1,0-1,0.6C3.5,21.2,3.5,21.9,3.5,22.6L3.5,22.6z"
                  />
                  <path
                    id="Path_2478"
                    fill={`${Color}`}
                    d="M15.9,4.5c1.4-1.3,3.3-1.7,5.2-1.3c1.3,0.3,2.5,1,3.3,2.1c1.8,2.3,1.5,5.7-0.6,7.7
	c-1.2,1.2-2.8,1.7-4.4,1.6c-1.6-0.1-3.1-1-4.1-2.3c-1.5,1.7-3.9,2.6-6.2,2.2c-1.7-0.3-3.2-1.2-4.2-2.5C2.7,9.2,3,5.3,5.6,3
	c2.8-2.5,7-2.3,9.5,0.5C15.4,3.8,15.7,4.1,15.9,4.5L15.9,4.5z M10.1,11.8c2.2,0,3.9-1.7,4-3.9c0-2.2-1.7-3.9-3.9-4
	c-2.1,0-3.9,1.7-4,3.8c-0.1,2.2,1.6,4,3.8,4C10,11.8,10,11.8,10.1,11.8z M16.8,8.7c0,1.6,1.2,2.9,2.8,3.1c1.6,0.1,3.1-1.1,3.2-2.8
	c0.1-1.6-1.2-3-2.8-3.1S16.9,7,16.8,8.7L16.8,8.7z"
                  />
                </svg>
              </a>
              <a
                href="https://profile.zjurl.cn/rogue/ugc/profile/?version_code=8.3.5&version_name=80305&user_id=769305538209357&media_id=1660594386634764&request_source=1&active_tab=dongtai&device_id=65&app_name=news_article&share_token=640E30FC-430B-427F-805F-4D64DF7935FA&tt_from=copy_link&utm_source=copy_link&utm_medium=toutiao_ios&utm_campaign=client_share"
                target="_blank"
                className="mx-1"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 34.2 30"
                  enable-background="new 0 0 34.2 30"
                  width="22px"
                  height="20px"
                >
                  <path
                    fill={`${Color}`}
                    d="M28.5,4.3c-0.9,0-1.8,0.1-2.7,0.1c-1.1,0.1-2.2,0.1-3.2,0.2c-1.3,0.1-2.6,0.1-3.8,0.2
	c-1.5,0.1-3,0.2-4.5,0.2c-1.1,0.1-2.2,0.1-3.3,0.2C9.8,5.3,8.7,5.4,7.5,5.4c-1,0.1-2.1,0.1-3.1,0.2L1.2,5.7H1.1v20.1
	c0.1,0,0.2,0,0.3,0c1-0.1,2.1-0.1,3.1-0.2l3.2-0.2c1-0.1,2-0.1,3-0.2s2-0.1,3-0.2l2.5-0.1l3.7-0.2l3-0.2l3.8-0.2c1-0.1,2-0.1,3-0.2
	l2.5-0.1c0.1,0,0.2,0,0.3,0V4C31.2,4.2,29.9,4.2,28.5,4.3z M6.9,9.7c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0.1C8.4,10.5,9,11.2,9.6,12
	l0,0c-0.1,0-0.2,0-0.4,0c-0.7,0-1.4,0.1-2.1,0.1c-0.1,0-0.1,0-0.2-0.1c-0.6-0.7-1.2-1.5-1.8-2.2c0,0,0,0-0.1-0.1H6.9z M9.6,15
	c-0.3,0-0.6,0-0.9,0.1c-0.5,0-1,0.1-1.5,0.1c-0.1,0-0.1,0-0.2-0.1c-0.6-0.7-1.2-1.5-1.8-2.2c0,0,0,0,0-0.1l0,0l0,0l0,0
	c0.4,0,0.7,0,1.1-0.1c0.5,0,0.9-0.1,1.4-0.1c0.1,0,0.1,0,0.2,0.1C8.4,13.5,9,14.2,9.6,15L9.6,15z M11.8,17.4c-1,1.4-2.1,2.7-3.1,4.1
	c-0.1,0.1-0.2,0.2-0.3,0.2c-0.8,0-1.6,0.1-2.4,0.1l-1.5,0.1c-0.1,0-0.2,0-0.2,0v-1.5c0.2,0,0.4,0,0.6,0c0.6,0,1.2-0.1,1.9-0.1
	c0.1,0,0.1,0,0.2-0.1c0.7-0.9,1.3-1.7,2-2.6c0,0,0,0,0.1-0.1l-4.7,0.3v-1.5l6.8-0.4v-0.2c0-2.1,0-4.2,0-6.3c0-0.1,0-0.2,0.2-0.2
	c0.7,0,1.4-0.1,2-0.1h0.1v6.7l2.9-0.2V17c-0.3,0-0.6,0-0.9,0.1c-1.1,0.1-2.2,0.1-3.2,0.2C11.9,17.3,11.8,17.3,11.8,17.4z M16.1,21.2
	c-0.7,0-1.4,0.1-2,0.1c-0.1,0-0.2,0-0.2-0.1c-0.6-1.1-1.2-2.3-1.7-3.4v-0.1l0,0c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.1,0,0.1,0.1
	c0.6,1.2,1.2,2.3,1.8,3.5c0,0,0,0,0,0.1C16.3,21.2,16.2,21.2,16.1,21.2z M19.9,7.5c0.5,0.2,1,0.5,1.5,0.7c0.3,0.1,0.5,0.2,0.8,0.2
	c1.4-0.1,2.8-0.2,4.2-0.2c1-0.1,2-0.1,3-0.2h0.1c0,0.1,0,0.1,0,0.2c0,0.8,0,1.6,0,2.3c0,0.1,0,0.2-0.1,0.2c-1.2,0.6-2.3,1.1-3.5,1.7
	l-0.2,0.1c0.1,0,0.1,0,0.1,0.1c0.9,0.3,1.7,0.6,2.6,1c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9-0.1V15c-0.2,0-0.4,0-0.6,0
	c-0.3,0-0.7,0-1,0.1c-0.1,0-0.1,0-0.2,0c-1.4-0.5-2.7-1-4.1-1.5c-0.1,0-0.2,0-0.4,0c-1.3,0.7-2.6,1.3-4,2c-0.1,0.1-0.2,0.1-0.3,0.1
	c-0.5,0-1,0.1-1.5,0.1v-1.5c0.3,0,0.6,0,0.9-0.1c0.1,0,0.2,0,0.4-0.1c0.8-0.4,1.7-0.8,2.5-1.2c0,0,0.1,0,0.1-0.1l-1.6-0.6l0.9-1.4
	l1.4,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.1,0,0.1,0,0.2,0c1.1-0.5,2.2-1.1,3.3-1.6c0.2-0.1,0.1-0.3,0.1-0.4c-0.4,0-0.9,0-1.3,0.1L23.1,10
	c-0.8,0-1.6,0.1-2.4,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.6-0.7,1.2-1.1,1.8l0,0c0,0.1-0.1,0.1-0.2,0.1c-0.6-0.2-1.2-0.4-1.8-0.7
	L19.9,7.5z M21.4,18.1C20.9,19,20.5,20,20,20.9c0,0.1-0.1,0.1-0.2,0.1c-0.7,0-1.3,0.1-2,0.1c-0.1,0-0.1,0-0.2,0l0,0
	c0-0.1,0.1-0.1,0.1-0.2c0.5-0.9,0.9-1.8,1.4-2.7c0-0.1,0.1-0.1,0.2-0.1c0.7,0,1.4-0.1,2.1-0.1h0.1C21.5,18,21.4,18.1,21.4,18.1z
	 M24.8,20.7l-2.4,0.1v-3.4l-4.8,0.3c0-0.1,0-0.1,0-0.1c0-0.4,0-0.8,0-1.3c0-0.1,0-0.1,0.1-0.2c1.5-0.1,3-0.2,4.6-0.3
	c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.4,0-0.6l2.3-0.1v0.8l4.8-0.3v1.5l-4.8,0.3V20.7z M29.3,20.5c-0.5,0-1,0.1-1.5,0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.5-0.9-1.1-1.8-1.6-2.6v-0.1l0,0c0.3,0,0.6,0,0.8,0c0.5,0,0.9-0.1,1.4-0.1c0.1,0,0.1,0,0.2,0.1
	c0.5,0.9,1.1,1.8,1.6,2.6c0,0,0,0,0,0.1C29.8,20.4,29.6,20.4,29.3,20.5z"
                  />
                </svg>
              </a>
              <span className={`roadto ${textColor} pt-1`} dir="auto">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  width="84px"
                  height="20px"
                  y="0px"
                  viewBox="0 0 126.4 30"
                  enable-background="new 0 0 126.4 30"
                >
                  <path
                    fill={`${Color}`}
                    d="M12.3,5.2c-0.3,0-0.5,0-0.8,0C6.1,5.3,1.9,9.9,2.1,15.3c0,0.2,0,0.4,0,0.7c0.2,5,4.4,8.9,9.4,8.7l0.3-2.3
	c-0.1,0-0.2,0-0.3,0c-3.8-0.1-6.8-3.3-6.7-7.1c0-0.2,0-0.4,0-0.6c0.1-4,3.5-7.2,7.6-7.1c3.7,0,6.6,2.3,6.6,5.9
	c0,1.8-0.9,3.8-2.8,3.8v-6.4c-1.2-0.1-2.3-0.2-3.4-0.2c-3.2,0-5.5,1.3-5.5,5.3c0,2.2,1,3.9,2.8,3.9c1.2,0,2.1-0.6,2.9-2.3v2h2.5
	c3.9,0,6.2-2.7,6.2-6.3C21.5,8.4,17.4,5.2,12.3,5.2z M13.2,13.3c-0.5,2.9-0.9,4.2-1.8,4.2c-0.6,0-1-1.3-1-2.7c0-1,0.4-1.6,1.9-1.6
	C12.6,13.2,12.9,13.2,13.2,13.3L13.2,13.3z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M79.4,10.6c0-2.8-2-5-6.2-5c-1.9,0-3.8,0.4-5.6,1c0,1.2,0.2,2.3,0.5,3.5c1.4-0.6,2.9-0.9,4.5-1
	c1.5,0,2.6,0.7,2.6,2.3c0,2.1-1.9,4.1-7.8,8.4V23h12.3v-3.6h-9C77.2,16.4,79.4,13.7,79.4,10.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M89,5.6c-4.5,0-7.3,3.2-7.3,8.8c0,5.7,2.8,8.9,7.3,8.9s7.3-3.2,7.3-8.9S93.5,5.6,89,5.6z M89,19.8
	c-2.2,0-3-2.1-3-5.4S86.8,9,89,9s3,2.1,3,5.4S91.2,19.8,89,19.8z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M110.3,10.6c0-2.8-2-5-6.2-5c-1.9,0-3.8,0.4-5.6,1c0,1.2,0.2,2.3,0.5,3.5c1.4-0.6,2.9-0.9,4.5-1
	c1.5,0,2.6,0.7,2.6,2.3c0,2.1-1.9,4.1-7.8,8.4V23h12.3v-3.6h-9C108.1,16.4,110.3,13.7,110.3,10.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M115.7,19.4c6.4-3,8.6-5.7,8.6-8.8c0-2.8-2-5-6.2-5c-1.9,0-3.8,0.4-5.6,1c0,1.2,0.2,2.3,0.5,3.5
	c1.4-0.6,2.9-0.9,4.5-1c1.5,0,2.6,0.7,2.6,2.3c0,2.1-1.9,4.1-7.8,8.4V23h12.3v-3.6H115.7z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M39.5,15.6h-4.4c-1.1,0-1.7,0.4-1.9,1.3v-1.3h-3.1v7.5h3.1v-1.2c0.2,0.8,0.6,1.2,1.2,1.3h5.3
	c1.6,0.1,2.4-0.6,2.3-2v-3.5C42.1,16.3,41.3,15.6,39.5,15.6z M37.9,22.5h-3.6c-0.5,0-0.8-0.3-1-0.9v-4.4c0.3-0.6,0.9-0.9,1.6-0.8
	h2.5c1,0,1.5,0.4,1.5,1.1v3.9l0,0C38.9,22,38.4,22.5,37.9,22.5z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M39.2,13.1c1.9,0.1,2.8-0.5,2.6-1.7V9.5c0-1.2-0.8-1.8-2.6-1.7h-3v-2h-3.3v2h-2.4c-0.8,0-1.5,0.5-1.7,1.2
	V7.3h-2.9V19c0.1,1.5-0.3,2.9-1.1,4.1c2.9,0.2,4.2-0.8,4.1-2.9V12c0.2,0.7,0.8,1.2,1.5,1.1C30.4,13.1,39.2,13.1,39.2,13.1z
	 M28.7,11.6V9.2c0.3-0.4,0.7-0.6,1.1-0.6h7.4c1,0,1.4,0.3,1.4,1.1v1.6c0,0.7-0.4,1-1.3,1h-7.5C29.3,12.3,28.9,12,28.7,11.6z"
                  />
                  <path
                    fill={`${Color}`}
                    d="M56.2,11.1c0.2,0.7,0.8,1.1,1.8,1.1h1.7c1.8,0.1,2.6-0.7,2.6-2.2V8.2c0-1.4-0.9-2.1-2.6-2.1H58
	c-1.1,0-1.7,0.3-1.8,1.1V6.1h-2.9v6h2.9V11.1z M56.2,7.6c0.2-0.5,0.8-0.7,1.3-0.7h0.8c0.9,0,1.3,0.4,1.3,1.2v2
	c0.1,0.8-0.4,1.2-1.3,1.2h-0.8c-0.5,0.1-1.1-0.2-1.3-0.8V7.6z"
                  />
                  <polygon
                    fill={`${Color}`}
                    points="59.2,22.5 59.2,18.7 62.3,18.7 62.3,17.9 59.2,17.9 59.2,14.7 62.6,14.7 62.6,14 53.1,14 53.1,14.7 
	56.3,14.7 56.3,17.9 53.3,17.9 53.3,18.7 56.3,18.7 56.3,22.5 52.5,22.5 52.5,23.3 62.7,23.3 62.7,22.5 "
                  />
                  <path
                    fill={`${Color}`}
                    d="M52.8,20.5v-6.1c0.1-1.3-0.7-1.8-2.4-1.7h-0.2v-2.2H53V9.8h-2.8V7.5c1.3,0.2,2.5-0.5,2.9-1.7h-3.8
	c-0.2,0.8-0.8,1.1-1.9,1.1h-2.9v0.7h3v2.3h-3v0.7h3V13c-0.3,0.2-0.5,0.4-0.6,0.7v-1h-2v7.9c0.1,0.7-0.1,1.4-0.4,2
	c1.8,0.1,2.6-0.6,2.4-2.3V14c0.1-0.2,0.3-0.4,0.6-0.5v10.3h2.6V13.4c0.4,0.1,0.7,0.4,0.6,1.1v5.8c-0.1,1.6,0.7,2.3,2.4,2.2
	C52.9,21.9,52.8,21.2,52.8,20.5z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;

import React from "react";
import { useMsal } from "@azure/msal-react";
import { registerRequest } from "../../services/azure/authConfig";
import { useTranslation } from "next-i18next";

const SignInButton = () => {
  const { instance } = useMsal();
  const { t } = useTranslation("common");

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(registerRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(registerRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <a
      className="mb-0 ps-ar-2-x text-nowrap signin-btn"
      onClick={() => handleLogin("redirect")}
      style={{ cursor: "pointer" }}
    >
      {t("signin")}
    </a>
  );
};

export default SignInButton;

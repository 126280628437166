import { useCallback, useState } from "react";
import ActiveLink from "../ActiveLink";
import LanguageToggler from "./languageToggler";
import { useTranslation } from "next-i18next";
import { useIsAuthenticated } from "@azure/msal-react";
import { useUserInfo } from "@services/apiHooks";

export default function SubNavLogged({ close }) {
  const { t } = useTranslation("common");
  const [isActive, setActive] = useState(false);
  const [activemenu, setActiveMenu] = useState("");

  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);
  const accreditationId = user?.personal_info?.accreditation_id;

  const toggleActive = (e) => {
    if (activemenu !== e) {
      setActiveMenu(e);
    } else {
      setActiveMenu("");
    }
  };

  return (
    <>
      <ul className="text-center flex-grow-1">
        <li className="has-dropdown">
          <a
            id="news-services"
            onClick={(e) => toggleActive(e.currentTarget.id)}
            className={`${activemenu == "news-services" ? "active" : ""} `}
          >
            {t("newsServicestitle")}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.727"
              height="8.469"
              viewBox="0 0 14.727 8.469"
            >
              <path
                id="Path_6"
                data-name="Path 6"
                d="M619.116,352.352l-7.365-7.363,1.106-1.106,6.259,6.258,6.257-6.257,1.106,1.106Z"
                transform="translate(-611.751 -343.883)"
                fill="#8a1538"
              />
            </svg>
          </a>
          <div>
            <ul>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/general-insights"
                >
                  <a onClick={close}> {t("general-insights-title")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/official-media-releases"
                >
                  <a onClick={close}>{t("official-media-releases")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/fwc-2022-fixtures-results"
                >
                  <a onClick={close}> {t("fixtures-results-title")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/albums"
                  // href="/comingsoon"
                >
                  <a onClick={close}>{t("Photo-&-Video-Library")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/official-venues"
                >
                  <a onClick={close}> {t("officialVenuestitle")}</a>
                </ActiveLink>
              </li>
            </ul>
            <ul>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/news-services/non-official-venues"
                >
                  <a onClick={close}>{t("non-official-venues-title")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href={t("Ambassadorslinkpage")}
                >
                  <a onClick={close} className="" target="_blank">
                    {t("HC-Ambassadors")}
                  </a>
                </ActiveLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="has-dropdown">
          <a
            id="media-services"
            onClick={(e) => toggleActive(e.currentTarget.id)}
            className={`${activemenu == "media-services" ? "active" : ""} `}
          >
            {t("mediaServicesTitle")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.727"
              height="8.469"
              viewBox="0 0 14.727 8.469"
            >
              <path
                id="Path_6"
                data-name="Path 6"
                d="M619.116,352.352l-7.365-7.363,1.106-1.106,6.259,6.258,6.257-6.257,1.106,1.106Z"
                transform="translate(-611.751 -343.883)"
                fill="#8a1538"
              />
            </svg>
          </a>
          <div>
            <ul>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/entry-to-qatar-and-covid-19-policy"
                >
                  <a onClick={close}> {t("entrytoqatar-subnav")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/host-country-media-accreditation"
                >
                  <a onClick={close} className="">
                    {t("accreditation-subnav")}
                  </a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/host-country-media-accomodation"
                >
                  <a onClick={close}> {t("accommodation-subnav")} </a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/transportation"
                >
                  <a onClick={close}>{t("transportation-subnav")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/host-country-media-centre"
                >
                  <a onClick={close}>{t("mediacenter-subnav")}</a>
                </ActiveLink>
              </li>
            </ul>
            <ul>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/host-country-broadcast-facilities"
                >
                  <a onClick={close}> {t("broadcast-subnav")}</a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/equipment-custom-clearance"
                >
                  <a onClick={close} className="">
                    {t("clearance-subnav")}
                  </a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/filming-permits"
                >
                  <a onClick={close} className="">
                    {t("filming-subnav")}
                  </a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/prohibited-items"
                >
                  <a onClick={close} className="">
                    {t("prohibited-subnav")}
                  </a>
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  activeClassName="active"
                  href="/media-services/spectrum-policy"
                >
                  <a onClick={close} className="">
                    {t("spectrum-subnav")}
                  </a>
                </ActiveLink>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <ActiveLink activeClassName="active" href="/explore-qatar">
            <a onClick={close}>{t("menu3")}</a>
          </ActiveLink>
        </li>

        {(accreditationId === 2 || accreditationId === 3) && (
          <li className="has-dropdown">
            <a
              id="bookings"
              onClick={(e) => toggleActive(e.currentTarget.id)}
              className={`${activemenu == "bookings" ? "active" : ""} `}
            >
              Bookings
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.727"
                height="8.469"
                viewBox="0 0 14.727 8.469"
              >
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M619.116,352.352l-7.365-7.363,1.106-1.106,6.259,6.258,6.257-6.257,1.106,1.106Z"
                  transform="translate(-611.751 -343.883)"
                  fill="#8a1538"
                />
              </svg>
            </a>
            <div>
              <ul>
                <li>
                  <ActiveLink
                    activeClassName="active"
                    href="/bookings/media-events"
                  >
                    <a onClick={close}>Media Events</a>
                  </ActiveLink>
                </li>
                <li>
                  <ActiveLink
                    activeClassName="active"
                    href="/bookings/interviews"
                  >
                    <a onClick={close}>Interviews</a>
                  </ActiveLink>
                </li>
              </ul>
              <ul>
                <li>
                  <ActiveLink activeClassName="active" href="/bookings/studios">
                    <a onClick={close}>Studios</a>
                  </ActiveLink>
                </li>
              </ul>
            </div>
          </li>
        )}
      </ul>

      <div className="d-xl-none mt-auto">
        <LanguageToggler />
      </div>
    </>
  );
}

import styles from "./partners.module.scss";
import { regions } from "../../constants/regions";
import { Fragment } from "react";
import { useRegionInfo } from "../../services/apiHooks";
import { useRouter } from "next/router";

const Partners = () => {
  const router = useRouter();
  const { region } = useRegionInfo(router.locale);

  const currentRegion = regions[`${region ?? 1}`];

  return (
    <section className={`${styles.partners} my-4-py-5-my-sm-5`}>
      {/* <div className="container">
        {currentRegion.fifaPartners.show && (
          <Fragment>
            <h5 className="text-center pb-2">FIFA PARTNERS</h5>
            <div className={styles.partnersList}>
              {currentRegion?.fifaPartners.logos.map(({ img, alt }, i) => (
                <div key={i}>
                  <img src={img} alt={alt} />
                </div>
              ))}
            </div>
          </Fragment>
        )}

        {currentRegion.fifaWorldCupSponsers.show && (
          <Fragment>
            <h5 className="text-center pt-3 pb-2">FIFA WORLD CUP SPONSERS</h5>
            <div className={styles.partnersList}>
              {currentRegion?.fifaWorldCupSponsers.logos.map(
                ({ img, alt }, i) => (
                  <div key={i}>
                    <img src={img} alt={alt} />
                  </div>
                )
              )}
            </div>
          </Fragment>
        )}

        {currentRegion.regionalSupporters.show && (
          <Fragment>
            <h5 className="text-center pt-3 pb-2">REGIONAL SUPPORTERS</h5>
            <div className={styles.partnersList}>
              {currentRegion?.regionalSupporters.logos.map(
                ({ img, alt }, i) => (
                  <div key={i}>
                    <img src={img} alt={alt} />
                  </div>
                )
              )}
            </div>
          </Fragment>
        )}
      </div> */}
    </section>
  );
};

export default Partners;

import axios from "axios";
import Cookies from "js-cookie";
import Router from "next/router";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./azure/authConfig";
import { AuthRoutes } from "../components/Page";

const msalInstance = new PublicClientApplication(msalConfig);

export const servicesInstance = axios.create({
  baseURL: `https://${process.env.NEXT_PUBLIC_AZURE_URL_AD_DOMAIN}.onmicrosoft.com/${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`,
});

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get("access_token")}`;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const isNotAuth = AuthRoutes.includes(Router.pathname);
      msalInstance.logoutRedirect({
        ...loginRequest,
        postLogoutRedirectUri: !isNotAuth ? Router.pathname : "/pending",
      });
    } else return Promise.reject(error);

    // if (error.response.status === 401 && Router.pathname !== "/login") {
    //   console.log("redirect to login if not authenticated");
    //   Router.push({
    //     pathname: "/login",
    //     query: {
    //       redirectTo: Router.asPath,
    //     },
    //   });
    //   Cookies.remove("access_token");
    //   window.localStorage.removeItem("user_profile");
    // } else {
    //   return Promise.reject(error);
    // }
  }
);

instance.defaults.headers.post["Accept"] = "application/json";

export default instance;

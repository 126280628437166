import { useState } from "react";
import ActiveLink from "../ActiveLink";
import LanguageToggler from "./languageToggler";
import { useTranslation } from "next-i18next";
export default function SubNav({ close }) {
  const { t } = useTranslation("common");
  return (
    <>
      <ul className="text-center flex-grow-1">
        <li>
          <ActiveLink activeClassName="active" href="/media-and-events">
            <a onClick={close}>{t("menu1")}</a>
          </ActiveLink>
        </li>
        <li>
          <ActiveLink activeClassName="active" href="/accreditation&permits">
            <a onClick={close}>{t("menu2")}</a>
          </ActiveLink>
        </li>
        {/* <li>
          <ActiveLink activeClassName="active" k href="/explore-qatar">
            <a onClick={close}>{t("menu3")}</a>
          </ActiveLink>
        </li> */}
        {/* <li>
       
        </li> */}
      </ul>
      <div className="d-xl-none px-4-x px-xl-0 mt-auto">
        <LanguageToggler />
      </div>
    
    </>
  );
}

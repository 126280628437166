import { Fragment } from "react";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../services/azure/authConfig";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import styles from "../../styles/thank-you.module.scss";
import { useUserInfo } from "../../services/apiHooks";
import {
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import Layout from "../../components/Layouts";
import Nav from "../../components/Navi";
import Footer from "../../components/Footers";

const ErrorComponent = ({ error }) => {
  return (
    <Fragment>
      <Nav />
      <div className="col-md-8 col-11 mx-auto mt-5">
        <div
          className={`${styles.text} col-md-8 text-center ff-graphik mx-auto mb-4`}
        >
          <MessageBar messageBarType={MessageBarType.error}>
            An Error Occurred {error ? `: ${error.errorCode}` : ""}
          </MessageBar>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

const Loading = () => {
  return (
    <Fragment>
      <Nav />
      <div className="col-md-8 col-11 mx-auto mt-5">
        <div
          className={`${styles.text} col-md-8 text-center ff-graphik mx-auto mb-4`}
        >
          <Spinner
            label="Authentication in progress..."
            size={SpinnerSize.large}
          />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

const AuthHoc = ({ children }) => {
  const { instance, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);

  const router = useRouter();

  const { status_level, status, hayya_status, accreditation_id } =
    user?.personal_info ?? {};

  const isTester = user?.role === "TESTER";

  const isScApproved =
    status_level === "SC" && status === "approved" && accreditation_id === 1;

  const isHayaApproved =
    status_level === "HAYYA" &&
    status === "approved" &&
    hayya_status === "Approved" &&
    accreditation_id !== 1;

  const isPermitsAccredited =
    accreditation_id !== 1 &&
    router.pathname === "/media-services/equipment-clearance";

  // useEffect(() => {
  //   if (inProgress === InteractionStatus.None) {
  //     console.log("requestProfileData");
  //   }
  // }, [inProgress, instance]);

  useEffect(() => {
    if (user) {
      // old status
      //   if (
      //     user?.personal_info?.status !== "approved" &&
      //     user?.personal_info?.status !== "approved_lusail"
      //   )
      //     router.replace("/");

      // if (!isTester && !isScApproved && !isHayaApproved && !isPermitsAccredited)
      //   router.replace("/");
    }
  }, [user]);

  const authRequest = {
    ...loginRequest,
    // redirectUri: "/",
    // navigateToLoginRequestUrl: false,
  };

  // old status
  // if (
  //   (isAuthenticated && isUserInfoLoading) ||
  //   (isAuthenticated &&
  //     user?.personal_info?.status !== "approved" &&
  //     user?.personal_info?.status !== "approved_lusail")
  // )
  //   return <Loading />;

  // if (
  //   (isAuthenticated && isUserInfoLoading) ||
  //   (isAuthenticated &&
  //     !isTester &&
  //     !isScApproved &&
  //     !isHayaApproved &&
  //     !isPermitsAccredited)
  // )
  //   return <Loading />;

  if (isAuthenticated && isUserInfoError) return <ErrorComponent />;

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default AuthHoc;

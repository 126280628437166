import { useEffect, useCallback, useState, Fragment } from "react";
import { useRouter } from "next/router";
import styles from "./nav.module.scss";
import SubNav from "./SubNav";
import LanguageToggler from "./languageToggler";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { isMobile } from "react-device-detect";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";

import SubNavLogged from "./subNavLogged";
import { useUserInfo } from "../../services/apiHooks";
export const noArabicRoutes = [
  // "/faqs",
  "/bookings/media-events",
  "/news-services/official-media-releases",
];
export default function Nav() {
  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);

  const { t } = useTranslation("common");
  const [scrollY, setScrollY] = useState(false);

  const [isActive, setActive] = useState(false);
  const toggleActive = useCallback(
    () => setActive((isActive) => !isActive),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      if (window.screen.width < 1199) setScrollY(120);
    };

    // just trigger this so that the initial state
    // is updated as soon as the component is mounted
    // related: https://stackoverflow.com/a/63408216
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closemenu = () => setActive(!isActive);

  const { status_level, status, hayya_status, accreditation_id } =
    user?.personal_info ?? {};

  const isTester = user?.role === "TESTER";

  const isScApproved =
    status_level === "SC" && status === "approved" && accreditation_id === 1;

  const isHayaApproved =
    status_level === "HAYYA" &&
    status === "approved" &&
    hayya_status === "Approved" &&
    accreditation_id !== 1;

    const router = useRouter();
    const isNotArabicRoutes = noArabicRoutes.includes(router.pathname);
  return (
    <div
      className={`${styles.navWrapper} ${scrollY > 42 ? styles.scrolled : ""} ${
        isMobile ? styles.scrolled : "notmobile"
      }`}
    >
      <nav className={`${styles.vmcNav}`}>
      <div className="header-top"><div className="container">
        <div className="row justify-content-end"><div className="col-auto d-flex align-items-center"><nav className="header-top-nav">
        <div className="w-100 py-xl-0 px-5 px-md-0 d-flex btm justify-content-end">
        <Link
          href=""
          locale={router.locale === "en" && !isNotArabicRoutes ? "ar" : "en"}
        >
          <a className={`${styles.langName} mb-0 `}>{t("language")}</a>
        </Link>
      </div>
    {/* <ul><li className="dropdown dropdown1 accessibility"><a className="dropdown-toggle" href="#" role="button" id="accessibilityDropdown" data-bs-toggle="dropdown" aria-expanded="false">Accessibility</a><ul className="dropdown-menu  menu-transition" aria-labelledby="accessibilityDropdown"><li><div className="d-flex align-items-center mb-1"><div className="flex-grow-1">Text size</div><div className="flex-shrink-0"><div className="d-flex acce-fontsize"><div className="nav-item"><a className="nav-link" id="decreasetext" href="#" title="Decrease text size">-</a></div><div className="nav-item"><a className="nav-link" id="increasetext" href="#" title="Increase text size">+</a></div></div></div></div></li><li><div className="d-flex align-items-center"><div className="flex-grow-1">Contrast</div><div className="flex-shrink-0"><div className="d-flex"><div className="nav-item"><a className="nav-link contrast px-0" href="#"><div className="t-changer"></div></a></div></div></div></div></li></ul></li></ul> */}
         </nav></div></div></div></div>

        <div className={`${styles.mainNav}`}>
          <div>
            <div className=" header-scrolled">
              <div className="container">
              <div className=" d-flex justify-content-between align-items-center">
                <Link href="/">
                  <a className={`${styles.logo} d-flex align-items-center`}>
                    {/* <img
                      src="/assets/svg/logo.svg"
                      alt="Hayya Media Portal Logo"
                    /> */}
                                  <img src="/assets/images/Logo.png"  alt="HMP Logo" width="100px"/>

                    {/* <img
                      src={`${
                        router.locale === "en"
                          ? "/assets/svg/logo-scrolled.svg"
                          : "/assets/svg/logo-scrolled-ar.svg "
                      }`}
                      className={`${styles.logoScrolled}`}
                      alt="Hayya Media Portal Logo"
                    /> */}
                    {/* <img src="/assets/svg/header-motif.svg" className={`${styles.logoScrolled}`}/> */}
                    {/* <h4 className={`${styles.subLogo} mb-0 mt-1 mx-3 logoName`}>
                      {t("logoName")}
                    </h4> */}
                  </a>
                </Link>
                <div className="d-flex align-items-center">
                <div
                  className={`${isActive ? styles.toggled : ""} ${
                    styles.scrolledSubNav
                  } ${styles.subNav} subNav scrolledSubNav a`}
                >
                  <div>
                    <AuthenticatedTemplate>
                      {/* old Status */}
                      {/* {user && (
                        <Fragment>
                          {user?.personal_info?.status === "approved" ||
                          user?.personal_info?.status === "approved_lusail" ? (
                            <SubNavLogged close={closemenu} />
                          ) : (
                            <SubNav close={closemenu} />
                          )}
                        </Fragment>
                      )} */}

                      {user && (
                        <Fragment>
                          {isTester || isScApproved || isHayaApproved ? (
                            <SubNavLogged close={closemenu} />
                          ) : (
                            <SubNav close={closemenu} />
                          )}
                        </Fragment>
                      )}
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                      <SubNav close={closemenu} />
                    </UnauthenticatedTemplate>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-xl-flex align-items-center d-none">
                    <LanguageToggler />
                  </div>
                  <button
                    className={`${isActive ? "toggled" : ""} menuToggler ms-3`}
                    onClick={toggleActive}
                  ></button>
                </div></div>
                </div> </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <section className={`${styles.subNav} subNav d-none d-xl-block `}>
        <div className="container">
          <AuthenticatedTemplate>
            {user && (
              <Fragment>
                {isTester || isScApproved || isHayaApproved ? (
                  <SubNavLogged close={closemenu} />
                ) : (
                  <SubNav close={closemenu} />
                )}
              </Fragment>
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SubNav close={closemenu} />
          </UnauthenticatedTemplate>
        </div>
      </section> */}
    </div>
  );
}
